import React, { useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { v4 as uuid } from "uuid"
import { Input, Form } from "antd"
import { ReminderTime, Sequence } from "learnink-common/lib/domains/sequences/Sequence.model"
import * as S from "../../styles/PageStyles"
import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import { ModalTypes } from "../../../pages/learningPaths/LearningPathBuilder"
import FormModal from "../../common/FormModal"
import { selectOrgImg } from "../../../redux/org/org.selectors"
import LpImageInput from "../../common/LpImageInput"
import BigOnOffButton from "../../common/BigOnOffButton"
import { faUser, faUsers } from "@fortawesome/free-solid-svg-icons"
import { LearningPathContent } from "../../../pages/learningPaths/utils/LearningPathContent"
import TooltipIcon from "../../common/TooltipIcon"

interface Props {
	orgId: string
	setModalType: React.Dispatch<React.SetStateAction<ModalTypes>>
	handleSubmit: (sequence: Sequence) => void
	learningPath: LearningPath
	sequenceIndex: number
	sequence?: Sequence
	orgImage: string
}

const defaultSequence = {
	numDays: 7,
	strictlyOrdered: true,
	staggerProgress: false,
	reminderSettings: {
		weekSchedule: {
			SUN: false,
			MON: true,
			TUE: true,
			WED: true,
			THU: true,
			FRI: true,
			SAT: false
		},
		numAttempts: 3,
		initialReminderTime: "08:00" as ReminderTime,
		followUpReminderTime: undefined
	}
}

const CreateEditSequence = ({
	sequence,
	orgId,
	setModalType,
	handleSubmit,
	learningPath,
	sequenceIndex,
	orgImage
}: Props) => {
	const [classroom, setClassroom] = useState<boolean>(sequence ? sequence.classroom : false)
	const [sequenceImage, setSequenceImage] = useState<string>(sequence ? sequence.image : orgImage)
	const previousSequence = sequenceIndex === 0 ? undefined : learningPath.sequences[sequenceIndex - 1]
	let unlockTrigger =
		sequenceIndex === 0
			? "start_learning_path"
			: classroom === true
			? "start_learning_path"
			: new LearningPathContent(learningPath).getUnlockTrigger()

	const handleCreateSequence = (values: any) => {
		const { displayName } = values
		if (!sequence) {
			const unlockDelayDays =
				sequenceIndex === 0 || unlockTrigger === "finish_previous"
					? 0
					: learningPath.sequences[sequenceIndex - 1].deadlineDay + 1
			const numDays = classroom ? 1 : defaultSequence.numDays
			const deadlineDay =
				unlockTrigger === "start_learning_path"
					? unlockDelayDays + numDays - 1
					: sequenceIndex === 0
					? numDays - 1
					: learningPath.sequences[sequenceIndex - 1].deadlineDay + numDays
			const newSequence: Sequence = {
				id: uuid(),
				orgId,
				internalName: displayName,
				displayName: displayName,
				image: sequenceImage,
				language: "en",
				classroom,
				goal: "custom",
				strictlyOrdered: true,
				staggerProgress: false,
				numDays,
				deadlineDay,
				content: [],
				certificate: {
					type: "certificate",
					image: previousSequence
						? previousSequence.certificate.image
						: "https://learnink-user-static.s3.eu-west-2.amazonaws.com/images/certificate_background_default.png"
				},
				unlockTrigger,
				unlockDelayDays,
				reminderSettings: defaultSequence.reminderSettings,
				isActive: true,
				learningPathId: learningPath.id
			}
			handleSubmit(newSequence)
		} else {
			const unlockDelayDays =
				sequenceIndex === 0 || unlockTrigger === "finish_previous"
					? 0
					: learningPath.sequences[sequenceIndex - 1].deadlineDay + 1
			const numDays = classroom ? 1 : sequence.numDays
			const deadlineDay =
				unlockTrigger === "start_learning_path"
					? unlockDelayDays + numDays - 1
					: sequenceIndex === 0
					? numDays - 1
					: learningPath.sequences[sequenceIndex - 1].deadlineDay + numDays
			const updatedSequence = {
				...sequence,
				image: sequenceImage,
				internalName: displayName,
				displayName: displayName,
				unlockTrigger,
				classroom,
				numDays,
				deadlineDay
			}
			handleSubmit(updatedSequence)
		}
	}

	const formElements = (
		<>
			<S.FormLabel>What type of training is this?</S.FormLabel>
			<TooltipIcon tooltip="Select in-person training if this will be completed in a classroom setting" />
			<S.FlexBoxStartRow style={{ marginTop: 10, marginBottom: 20 }}>
				<BigOnOffButton
					key="template"
					isOn={!classroom}
					color="green"
					text="Self-directed learning"
					faIcon={faUser}
					onClick={() => setClassroom(false)}
					width={200}
				/>
				<BigOnOffButton
					key="org_theme"
					isOn={classroom}
					color="purple"
					text="In-person training"
					faIcon={faUsers}
					onClick={() => setClassroom(true)}
					width={200}
					disabled={sequenceIndex > 0 && !learningPath.sequences[sequenceIndex - 1].classroom}
					tooltip="You cannot add in-person training after self-directed learning"
				/>
			</S.FlexBoxStartRow>
			<Form.Item
				label={<S.FormLabel>Name</S.FormLabel>}
				name="displayName"
				rules={[
					{
						required: true,
						message: "You need to add a name"
					}
				]}
				key="displayName"
				tooltip="This name will be on the certificate when your user completes this sequence of courses"
			>
				<Input placeholder="e.g. Communication Skills" />
			</Form.Item>
			<LpImageInput
				id="sequence-image-upload"
				initialImageUrl={sequenceImage}
				labelText="Image"
				uploadImageMessage=""
				thumbPreviewShape="square"
				buttonText="Upload a new image"
				thumbSize={"50px"}
				clickableImage={true}
				uploadButtonAsText={true}
				setNewImage={(imageUrl) => {
					setSequenceImage(imageUrl)
				}}
				labelFontSize="16px !important"
				tooltip="This image will appear in popups and animations in the user learning experience"
			/>
		</>
	)

	return (
		<FormModal
			title={sequence ? "Edit sequence" : "Add sequence"}
			isModalOpen={true}
			closeModal={() => setModalType("none")}
			modalState="confirm"
			handleSubmit={handleCreateSequence}
			formElements={formElements}
			submitButtonTitle={sequence ? "Save" : "Add"}
			initialValues={sequence ? { displayName: sequence.displayName } : {}}
			size="large"
		/>
	)
}

const mapStateToProps = createStructuredSelector({
	orgImage: selectOrgImg
})

export default connect(mapStateToProps)(CreateEditSequence)
