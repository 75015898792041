import { LanguageCode } from "learnink-common/lib/constants/language/LanguageCode"
import { CardType, Card } from "learnink-common/lib/domains/cards/Card.model"
import { QuestionStyle } from "learnink-common/lib/domains/cards/questions/BaseQuestionCard.model"
import { UUID } from "learnink-common/lib/types/types"
import { generateRandomElementFromArray } from "../formatters"

export const DEFAULT_VOICE_ID = "1343b029-3394-4028-9bd2-5f84ed577d1b"
export const DEFAULT_LANGUAGES: LanguageCode[] = ["en", "sw"]

const isEmpty = (string: string | undefined) => {
	return string === undefined || string.trim().length === 0
}

const defaultQuestionCardImgs = [
	"https://farminkweb.s3.amazonaws.com/f91c206d-8de2-4388-a51d-c425715a2557.png",
	"https://farminkweb.s3.eu-west-2.amazonaws.com/b8c1c783-f08f-43ac-b3d3-8261e7397ebe.png",
	"https://farminkweb.s3.eu-west-2.amazonaws.com/483e5278-33e8-41ee-bd56-98c12815593c.png",
	"https://farminkweb.s3.eu-west-2.amazonaws.com/10ae173b-e170-4fb4-a22b-c54afdd4d66d.png",
	"https://farminkweb.s3.eu-west-2.amazonaws.com/51718667-6e04-42e3-abf6-e549086884dd.png"
]

export function generateDefaultCardContent(type: CardType, style?: QuestionStyle) {
	if (type === "text") {
		return {
			type: type,
			from: "teacher",
			message: "",
			voiceClips: []
		}
	}
	if (type === "image") {
		return {
			type: type,
			from: "teacher",
			image: "",
			imageSettings: {
				animation: "off",
				popupLayout: "fitScreen"
			}
		}
	}
	if (type === "button") {
		return {
			type: type,
			from: "teacher",
			message: "",
			buttonText: "",
			url: "",
			voiceClips: []
		}
	}
	if (type === "callOut") {
		return {
			type: type,
			from: "teacher",
			backgroundStyle: "clouds",
			shape: "rounded",
			iconPlacement: "top",
			iconSize: "small",
			layout: "oneElement",
			elements: [
				{
					text: "Use these cards to highlight a concept or idea",
					imgUrl: "https://em-content.zobj.net/thumbs/144/google/350/light-bulb_1f4a1.png"
				}
			],
			logoPlacement: "none",
			animation: "popup",
			voiceClips: []
		}
	}
	if (type === "multipleChoiceSurvey") {
		return {
			type: type,
			from: "teacher",
			message: "",
			surveyOptions: [],
			voiceClips: []
		}
	}
	if (type === "textInputSurvey") {
		return {
			type: type,
			from: "teacher",
			message: ""
		}
	}
	if (type === "numberInputSurvey") {
		return {
			type: type,
			from: "teacher",
			message: ""
		}
	}
	if (type === "video") {
		return {
			type: type,
			from: "teacher",
			videoType: "youtube",
			videoId: "",
			videoThumbnail: ""
		}
	}
	if (type === "multipleChoiceQuestion" && style !== "booleanImage") {
		return {
			type: type,
			text: "",
			style: style,
			image: generateRandomElementFromArray(defaultQuestionCardImgs),
			answerTip: "",
			answerOptions: [
				{
					text: "",
					correct: true
				},
				{
					text: "",
					correct: false
				},
				{
					text: "",
					correct: false
				}
			],
			voiceClips: []
		}
	}
	if (type === "multipleChoiceQuestion" && style === "booleanImage") {
		return {
			type: type,
			text: "",
			style: "booleanImage",
			image: generateRandomElementFromArray(defaultQuestionCardImgs),
			answerTip: "",
			answerOptions: [
				{
					text: "true",
					correct: true
				},
				{
					text: "false",
					correct: false
				}
			],
			voiceClips: []
		}
	}
}

interface VoiceClipStatus {
	status: "complete" | "incomplete" | "none"
	voiceClip: string | undefined
}

export class CardModel {
	rawContent: Card

	constructor(rawContent: Card) {
		this.rawContent = rawContent || {}
	}

	noIds() {
		const card = JSON.parse(JSON.stringify(this.rawContent)) as Card
		card.textId = undefined

		return {
			...card,
			id: undefined,
			// @ts-ignore
			surveyOptions: card.surveyOptions
				? // @ts-ignore
				  card.surveyOptions.map((element) => {
						return {
							...element,
							id: undefined
						}
				  })
				: undefined,
			// @ts-ignore
			answerOptions: card.answerOptions
				? // @ts-ignore
				  card.answerOptions.map((element) => {
						return {
							...element,
							id: undefined
						}
				  })
				: undefined,
			// @ts-ignore
			voiceClips: card.voiceClips
				? card.voiceClips.map((element) => {
						return {
							...element,
							textId: undefined
						}
				  })
				: undefined
		}
	}

	getVoiceClipButtonStatus(languageCode: LanguageCode, voiceId: UUID): VoiceClipStatus {
		const card = this.rawContent
		if (card.type === "multipleChoiceQuestion") {
			const expectedNumVoiceClips = (card.answerOptions || []).length + 1
			let voiceClips: string[] = []
			if (
				card.voiceClips &&
				card.voiceClips.filter((item) => item.languageCode === languageCode && item.voiceId === voiceId)
					.length > 0
			) {
				voiceClips.push(
					card.voiceClips.filter((item) => item.languageCode === languageCode && item.voiceId === voiceId)[0]
						.fileUrl
				)
			}
			;(card.answerOptions || []).forEach((answer) => {
				if (
					answer.voiceClips &&
					answer.voiceClips.filter((item) => item.languageCode === languageCode && item.voiceId === voiceId)
						.length > 0
				) {
					voiceClips.push(
						answer.voiceClips.filter(
							(item) => item.languageCode === languageCode && item.voiceId === voiceId
						)[0].fileUrl
					)
				}
			})
			const numVoiceClipFiles = voiceClips.length
			const status =
				numVoiceClipFiles === 0
					? "none"
					: numVoiceClipFiles >= expectedNumVoiceClips
					? "complete"
					: "incomplete"
			return {
				status,
				voiceClip: status === "complete" ? voiceClips[0] : undefined
			}
		}
		if (card.type === "multipleChoiceSurvey") {
			const expectedNumVoiceClips = (card.surveyOptions || []).length + 1
			let voiceClips: string[] = []
			if (
				card.voiceClips &&
				card.voiceClips.filter((item) => item.languageCode === languageCode && item.voiceId === voiceId)
					.length > 0
			) {
				voiceClips.push(
					card.voiceClips.filter((item) => item.languageCode === languageCode && item.voiceId === voiceId)[0]
						.fileUrl
				)
			}
			;(card.surveyOptions || []).forEach((option) => {
				if (
					option.voiceClips &&
					option.voiceClips.filter((item) => item.languageCode === languageCode && item.voiceId === voiceId)
						.length > 0
				) {
					voiceClips.push(
						option.voiceClips.filter(
							(item) => item.languageCode === languageCode && item.voiceId === voiceId
						)[0].fileUrl
					)
				}
			})
			const numVoiceClipFiles = voiceClips.length
			const status =
				numVoiceClipFiles === 0
					? "none"
					: numVoiceClipFiles >= expectedNumVoiceClips
					? "complete"
					: "incomplete"
			return {
				status,
				voiceClip: status === "complete" ? voiceClips[0] : undefined
			}
		}
		if (
			card.voiceClips &&
			card.voiceClips.filter((item) => item.languageCode === languageCode && item.voiceId === voiceId).length > 0
		) {
			return {
				status: "complete",
				voiceClip: card.voiceClips
					.filter((item) => item.languageCode === languageCode && item.voiceId === voiceId)
					.map((item) => item.fileUrl)[0]
			}
		}
		return {
			status: "none",
			voiceClip: undefined
		}
	}

	findErrors() {
		const card = this.rawContent
		const errors: string[] = []

		if (
			["text", "multipleChoiceSurvey", "textInputSurvey", "numberInputSurvey"].includes(card.type) &&
			// @ts-ignore
			isEmpty(card.message)
		) {
			errors.push("Message cannot be blank")
		}
		if (card.type === "image" && isEmpty(card.image)) {
			errors.push("Image cannot be blank")
		}
		if (card.type === "video" && isEmpty(card.videoId)) {
			errors.push("No video file found")
		}
		if (card.type === "video" && !["youtube", "mux", "vimeo"].includes(card.videoType || "")) {
			errors.push("Invalid video type")
		}
		if (card.type === "button" && isEmpty(card.message)) {
			errors.push("Message cannot be blank")
		}
		if (card.type === "button" && isEmpty(card.buttonText)) {
			errors.push("Button title cannot be blank")
		}
		if (card.type === "button" && isEmpty(card.url)) {
			errors.push("Button url cannot be blank")
		}
		if (card.type === "multipleChoiceSurvey" && (card.surveyOptions || []).length === 0) {
			errors.push("Add at least one survey answer")
		}
		if (
			card.type === "multipleChoiceSurvey" &&
			(card.surveyOptions || []).filter((item) => item.text === "" || item.text === undefined).length > 0
		) {
			errors.push("Survey answer can't be blank")
		}
		if (card.type === "multipleChoiceQuestion" && isEmpty(card.text)) {
			errors.push("Question text cannot be blank")
		}
		if (card.type === "multipleChoiceQuestion" && isEmpty(card.image)) {
			errors.push("Question image cannot be blank")
		}
		if (
			card.type === "multipleChoiceQuestion" &&
			(card.answerOptions || []).filter((item) => item.text === "" || item.text === undefined).length > 0
		) {
			errors.push("Question answer can't be blank")
		}
		if (card.type === "multipleChoiceQuestion" && (card.answerOptions || []).length < 2) {
			errors.push("Add at least 2 answers")
		}
		return errors
	}
}
