import React from "react"
import { z } from "zod"
import { RegistrationStatus, User } from "learnink-common/lib/domains/userManagement/users/User.model"
import {
	Member,
	Operator,
	SearchFilter
} from "learnink-common/lib/domains/userManagement/userSearch/SearchFilter.model"
import { HeaderProps } from "./Header"

export type __User = User & {
	coursesStarted?: unknown
	coursesCompleted?: unknown
	actionList?: unknown
	certificateId: string
} //	TODO, this should probably not need to exist, we should make sure that the service has all the correct fields

export const TableColumnLabel = z.enum([
	"Checkbox",
	"Notify",
	"Full name",
	"Status",
	"Referrer",
	"User groups",
	"Learning path",
	"First name",
	"Last name",
	"Last active",
	"Custom ID",
	"User ID",
	"Phone number",
	"Sex",
	"Country",
	"Region",
	"Age",
	"Downloaded app",
	"Registered"
])

export type TableColumnLabel = z.infer<typeof TableColumnLabel>

export const FilterableMember = TableColumnLabel.exclude(["Checkbox", "Notify"])
export type FilterableMember = z.infer<typeof FilterableMember>

export type CellDisplay = string | React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> | null

export type UserGroupColours = Record<string, string>
export type UserGroupLabels = Record<string, string>

export const REGISTRATION_STATUS_COLOURS: { [key in RegistrationStatus]: string | undefined } = {
	registered: "green",
	unregistered: "blue",
	archived: undefined
}

export type InputType = "datePicker" | "numberInput" | "stringInput" | "selectMultiple"

export interface WidthProperties {
	width?: number
	minWidth?: number
	maxWidth?: number
}

export interface HeaderColumn extends WidthProperties {
	Header: CellDisplay
	accessor: TableColumnLabel
}

export interface ColumnDefinition extends WidthProperties {
	buildHeader: (props: HeaderProps) => CellDisplay
	buildRow: (user: __User, index: number) => CellDisplay
}

export interface Option {
	value: string | null
	label: string
}

export type Filter = SearchFilter & {
	typeLabel: string
	inputType: InputType
	valueLabels?: (string | number)[] // This field is intended for when the values aren't human readable
}

export interface FilterParams {
	member: Member
	operators: Operator[]
	selectedOperator: Operator
	inputType: InputType
	options?: Option[]
	values?: Option[]
}

export const OPERATOR_LABEL: { [key in Operator]: string } = {
	empty: "has no value",
	notEmpty: "has a value",
	equals: "equals",
	notEquals: "does not equal",
	gt: "is greater than",
	lt: "is less than",
	in: "is in",
	notIn: "is not in",
	includesAll: "includes all",
	notIncludesAll: "does not include all",
	includesAny: "includes any",
	notIncludesAny: "does not include any",
	hasValue: "has a value",
	hasNoValue: "has no value",
	contains: "contains",
	notContains: "does not contain"
}

export type ModalType = "notification" | "certificate"

export interface UserIdsSelectedProps {
	allUsersSelected: boolean
	selectedUserIds: string[]
	unselectedUserIds: string[]
}
