import { connect } from "react-redux"
import PanelContainer from "../editor/panels/common/PanelContainer"
import { Button, Form, Select } from "antd"
import { FormLabel } from "../../styles/PageStyles"
import TooltipIcon from "../../common/TooltipIcon"
import React, { useEffect, useState } from "react"
import Routes from "../../../util/Routes"
import { useApi } from "../../../util/useApi"
import { createStructuredSelector } from "reselect"
import { selectOrgId } from "../../../redux/org/org.selectors"
import { selectCurrentStageId } from "../../../redux/courses/courses.selectors"

interface VoiceProfile {
	id: string
	name: string
}

type VoiceProfileType = "teacher" | "learner"

interface Option {
	value: string
	label: string
}

interface Props {
	orgId: string
	stageId: string
	cardId: string
}

const languageCode = "en"

interface VoiceClipRequestParams {
	orgId: string
	languageCode: "en"
	cardIds?: string[]
	teacherVoiceProfileId: string
	learnerVoiceProfileId: string
}

const TextToSpeech = ({ orgId, stageId, cardId }: Props) => {
	const [teacherVoiceProfileId, setTeacherVoiceProfileId] = useState<string>("")
	const [learnerVoiceProfileId, setLearnerVoiceProfileId] = useState<string>("")

	const { setApiRequest: getDefaults, data: voiceProfileDefaults } = useApi<never, VoiceProfile[]>()
	const { setApiRequest: requestVoiceClips } = useApi<VoiceClipRequestParams, string>()

	useEffect(() => {
		getDefaults({ route: Routes.getVoiceProfileAIDefaults })
	}, [])

	const handleRequestVoiceClips = (cardId?: string) => {
		requestVoiceClips({
			route: Routes.requestVoiceClips,
			urlParams: { stageId },
			payload: getRequestParams(cardId),
			onSuccess: (progress) => {
				alert(progress)
			}
		})
	}

	useEffect(() => {
		const options = getVoiceOptions()
		setLearnerVoiceProfileId(options?.[0]?.value)
		setTeacherVoiceProfileId(options?.[1]?.value)
	}, [voiceProfileDefaults])

	const getVoiceOptions = (): Option[] => {
		return (voiceProfileDefaults || [])?.map(({ id, name }) => ({
			value: id,
			label: name
		}))
	}

	const getRequestParams = (cardId?: string): VoiceClipRequestParams => {
		return {
			orgId,
			languageCode,
			teacherVoiceProfileId,
			learnerVoiceProfileId,
			cardIds: cardId ? [cardId] : undefined
		}
	}

	const VoiceSelect = ({ type }: { type: VoiceProfileType }) => {
		const label = `${type === "teacher" ? "Teacher" : "Learner"} voice`
		const value = type === "teacher" ? teacherVoiceProfileId : learnerVoiceProfileId
		const onChange = type === "teacher" ? setTeacherVoiceProfileId : setLearnerVoiceProfileId

		return (
			<Form.Item
				label={
					<FormLabel>
						{label} <TooltipIcon tooltip="Choose from a list of available AI voices" />
					</FormLabel>
				}
			>
				<Select
					showArrow
					value={value}
					options={getVoiceOptions()}
					placeholder="Please select..."
					onChange={onChange}
				/>
			</Form.Item>
		)
	}

	return (
		<PanelContainer title="Text-to-speech">
			<VoiceSelect type="teacher" />
			<VoiceSelect type="learner" />

			<Form.Item>
				<Button type="primary" size="middle" shape="round" onClick={() => handleRequestVoiceClips()}>
					Process all cards
				</Button>
			</Form.Item>

			<Form.Item>
				<Button type="ghost" size="middle" shape="round" onClick={() => handleRequestVoiceClips(cardId)}>
					Process current card only
				</Button>
			</Form.Item>
		</PanelContainer>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	stageId: selectCurrentStageId
})

// @ts-ignore
export default connect(mapStateToProps)(TextToSpeech)
