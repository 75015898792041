import React from "react"
import { Button, Form, Input, Modal, Result } from "antd"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import FormModal from "../../common/FormModal"
import { LearningPathTheme, LearningPathType } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import { selectOrgId } from "../../../redux/org/org.selectors"
import { ShortId } from "learnink-common/lib/tools/randomCharacterGenerators/ShortIdGenerator"
import * as S from "../../styles/PageStyles"
import { ModalState } from "../../../util/model/PageStates"
import { ISO_8601_string } from "learnink-common/lib/types/types"

interface Props {
	isModalOpen: boolean
	setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
	modalState: ModalState
	handleSubmit: (
		type: LearningPathType,
		internalName: string,
		theme: LearningPathTheme,
		startAt: ISO_8601_string | undefined
	) => void
	orgId: string
	selectedLearningPathId: ShortId | undefined
}

const CreateLearningPath = ({
	isModalOpen,
	setIsModalOpen,
	modalState,
	handleSubmit,
	selectedLearningPathId,
	orgId
}: Props) => {
	const formElements = (
		<>
			<Form.Item
				label={<S.FormLabel>Name</S.FormLabel>}
				name="internalName"
				rules={[
					{
						required: true,
						message: "You need to add a name"
					}
				]}
				key="internalName"
				tooltip="Give you learning path a descriptive name. You can always change this later!"
			>
				<Input placeholder="e.g. Onboarding" />
			</Form.Item>
		</>
	)

	if (modalState === "complete") {
		return (
			<Modal footer={null} open={true} centered onCancel={() => setIsModalOpen(false)}>
				<Result
					status="success"
					title="You have a new learning path!"
					subTitle="Your learning path is empty. Let's start creating some content!"
					extra={[
						<Link to={`/org/${orgId}/create/learning-path/${selectedLearningPathId}`}>
							<Button type="primary" size="large" shape="round">
								Let's go!
							</Button>
						</Link>
					]}
				/>
			</Modal>
		)
	}

	return (
		<FormModal
			title="Create learning path"
			isModalOpen={isModalOpen}
			closeModal={() => setIsModalOpen(false)}
			modalState={modalState}
			handleSubmit={(values: any) => handleSubmit("SEGMENT", values.internalName, "ORG_THEME", undefined)}
			formElements={formElements}
			submitButtonTitle="Create"
			size="large"
		/>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId
})

export default connect(mapStateToProps)(CreateLearningPath)
