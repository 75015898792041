import styled from "styled-components"
import React, { CSSProperties } from "react"
import { ButtonProps, ButtonShape, ButtonType } from "antd/lib/button/button"
import { SizeType } from "antd/lib/config-provider/SizeContext"
import { Button } from "antd"

export const Countdown = styled.div`
	font-size: 100px;
	margin: auto;
	width: 100%;
	text-align: center;
`

export const RecordingWrapper = styled.div`
	font-size: 30px;
	text-align: center;
`

interface RecordingDotProps {
	redDotIsHidden: boolean
}

const recordingDotOpacity = ({ redDotIsHidden }: RecordingDotProps) => {
	return redDotIsHidden ? "0.05" : "1"
}

export const RecordingDot = styled.span<RecordingDotProps>`
	color: #e50303;
	vertical-align: text-bottom;
	line-height: 40px;
	opacity: ${recordingDotOpacity};
	transition: opacity 0.5s ease-in-out;
`

interface RecordingProps {
	timeFormatted: string
	redDotIsHidden: boolean
}

export const Recording = ({ timeFormatted, redDotIsHidden }: RecordingProps) => {
	return (
		<RecordingWrapper>
			<RecordingDot redDotIsHidden={redDotIsHidden}>⬤</RecordingDot> {timeFormatted}
		</RecordingWrapper>
	)
}

export const DEFAULT_WAVEFORM_OPTIONS = {
	waveColor: "#27c940",
	progressColor: "#d6ecd9",
	height: 60,
	normalize: true
}

export const OptionsFooter = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 40px;
	margin: 0 auto;
	padding-top: 20px;
	width: 100%;
`

type HugeButtonProps = ButtonProps & React.RefAttributes<HTMLElement> & {}

export const HugeButton = ({ ...props }: HugeButtonProps) => {
	const hugeProps = {
		...props,
		shape: "circle" as ButtonShape,
		size: "large" as SizeType,
		type: "primary" as ButtonType,
		style: {
			height: "60px",
			width: "60px"
		}
	}

	return <Button {...hugeProps} />
}

export const largeIcon: CSSProperties = { fontSize: "200%" }

interface CueCardProps {
	teacherOrLearner: "teacher" | "learner"
	textToRecord: string | undefined
}

export const CueCard = ({ teacherOrLearner, textToRecord }: CueCardProps) => {
	return (
		<div>
			{teacherOrLearner && <strong>{teacherOrLearner}:</strong>}
			{textToRecord && (
				<div
					style={{
						backgroundColor: "#f8f8ff",
						borderRadius: "5px",
						border: "1px solid #dadaff",
						padding: "4px 11px",
						marginTop: "10px"
					}}
				>
					{textToRecord}
				</div>
			)}
		</div>
	)
}
