import React from "react"
import { Button, DatePicker, InputNumber, Select, Space, Tag } from "antd"
import TextArea from "antd/lib/input/TextArea"
import { Filter, FilterParams, OPERATOR_LABEL, Option, TableColumnLabel } from "./UserSearch.model"
import { Operator } from "learnink-common/lib/domains/userManagement/userSearch/SearchFilter.model"
import { NonEmptyArray } from "zod-validation-error/dist/types/utils/NonEmptyArray"
import { CloseOutlined } from "@ant-design/icons"
import styled from "styled-components"
import { operatorRequiresValue } from "./UserSearchFilters"

const S = {
	ActiveFilterContainer: styled.div`
		border: solid 1px var(--light-grey);
		padding: 6px;
		display: inline-block;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	`,
	ActiveFilterInnerContainer: styled.div`
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	`
}

interface Props {
	filterParams: FilterParams
	columnLabel: TableColumnLabel
	setFilterParams: (params: FilterParams | undefined) => void
	filters: Filter[]
	setFilters: (filters: Filter[]) => void
}

export default function ActiveFilter({ filterParams, columnLabel, filters, setFilterParams, setFilters }: Props) {
	const isFirstFilter = filters.length === 0
	const canCreateFilter =
		filterParams?.member &&
		filterParams?.selectedOperator &&
		((filterParams?.values && filterParams?.values.length > 0) ||
			!operatorRequiresValue(filterParams?.selectedOperator))

	function toOption(value: string | number) {
		return { value: value.toString(), label: value.toString() }
	}

	const setListInput = (value: string) => {
		const values = value
			.replace(/\n/g, ",")
			.split(",")
			.map((item) => item.trim())
			.filter((item) => item.length > 0)
			.map(toOption)

		setFilterParams({ ...filterParams, values })
	}

	function setMultipleSelectInput(value: string): Option {
		const options = (filterParams.options || []).filter((option) => option.value === value)
		if (options.length > 1) throw new Error("Duplicated org groups...")
		if (options.length === 0) throw new Error("No org group found...")
		return { value, label: options[0].label }
	}

	const FilterOperatorSelect = (
		<Select
			style={{ width: 160 }}
			defaultValue={filterParams.selectedOperator}
			options={getOperatorOptions(filterParams.operators)}
			onChange={(selectedOperator) => setFilterParams({ ...filterParams, selectedOperator })}
		/>
	)

	const FilterDatePicker = (
		<DatePicker
			onChange={(date, dateString) =>
				setFilterParams({ ...filterParams, values: [{ value: dateString, label: dateString }] })
			}
		/>
	)

	const FilterNumberInput = (
		<InputNumber
			onChange={(value) => {
				if (!value) return setFilterParams(undefined)

				setFilterParams({
					...filterParams,
					values: [toOption(value)]
				})
			}}
		/>
	)

	const FilterListInput = (
		<TextArea
			onChange={(e) => {
				setListInput(e.target.value)
			}}
			autoSize={{ minRows: 1, maxRows: 6 }}
		/>
	)

	const FilterMultipleSelect = (
		<Select
			showSearch
			mode="multiple"
			showArrow={true}
			style={{ width: 300 }}
			options={filterParams.options}
			onChange={(valueKeys: string[] | undefined) => {
				const values = valueKeys?.map(setMultipleSelectInput)
				setFilterParams({ ...filterParams, values })
			}}
			filterOption={(input, option) => {
				return (
					!!option &&
					!!option.value &&
					(option.label.toString().toLowerCase().includes(input.toLowerCase()) ||
						option.value.toString().toLowerCase().includes(input.toLowerCase()))
				)
			}}
		/>
	)

	function buildFilters(): NonEmptyArray<Filter> {
		const newFilters = [...filters]

		newFilters.push({
			typeLabel: columnLabel,
			member: filterParams.member,
			operator: filterParams.selectedOperator,
			values: operatorRequiresValue(filterParams.selectedOperator)
				? (filterParams.values?.map((v) => v.value) as NonEmptyArray<string>)
				: [""],
			inputType: filterParams.inputType,
			valueLabels: filterParams.values?.map((v) => v.label)
		} as Filter)

		return newFilters as NonEmptyArray<Filter>
	}

	const AddFilterButton = (
		<Button
			type="primary"
			onClick={() => {
				setFilters(buildFilters())
				setFilterParams(undefined)
			}}
		>
			Add
		</Button>
	)

	const shouldShowValue = operatorRequiresValue(filterParams.selectedOperator)

	return (
		<Container>
			{isFirstFilter ? <Tag color="blue">WHERE</Tag> : <Tag color="volcano">AND</Tag>}

			{columnLabel}

			{filterParams.operators && FilterOperatorSelect}

			{shouldShowValue && filterParams.inputType === "datePicker" && FilterDatePicker}
			{shouldShowValue && filterParams.inputType === "numberInput" && FilterNumberInput}
			{shouldShowValue && filterParams.inputType === "stringInput" && FilterListInput}
			{shouldShowValue && filterParams.inputType === "selectMultiple" && FilterMultipleSelect}

			{canCreateFilter && AddFilterButton}

			<Button icon={<CloseOutlined />} onClick={() => setFilterParams(undefined)} />
		</Container>
	)
}

function Container({ children }: { children: React.ReactNode }) {
	return (
		<S.ActiveFilterContainer>
			<S.ActiveFilterInnerContainer>
				<Space>{children}</Space>
			</S.ActiveFilterInnerContainer>
		</S.ActiveFilterContainer>
	)
}

function getOperatorOptions(operators: Operator[]): Option[] {
	return (operators || []).map((operator) => ({
		value: operator,
		label: OPERATOR_LABEL[operator]
	}))
}
