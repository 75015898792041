import styled from "styled-components"

export const PaddedDivSmall = styled.div`
	padding: 5px;
`

export const PaddedDivMedium = styled.div`
	padding: 10px;
`

export const PaddedDivLarge = styled.div`
	padding: 15px;
`

export const FixedPageDiv = styled.div`
	overflow: hidden;
	height: 100vh;
`
export const FlexBoxStartRow = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
	flex-wrap: wrap;
`
export const FlexBoxColumnCentered = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	align-items: center;
`
export const Card = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid var(--v-light-grey);
	border-radius: 8px;
	margin-bottom: 20px;
`
export const CardBody = styled.div`
	flex: 1 1 auto;
	min-height: 1px;
	padding: 14px;
`
export const CardInfoIcon = styled.div`
	position: absolute;
	top: 12px;
	right: 12px;
	width: 15px;
	height: 15px;
	text-align: center;
	font-size: 15px;
	color: var(--light-grey);
`
export const Container = styled.div`
	-webkit-box-align: center;
	align-items: center;
	flex-direction: column;
	-webkit-box-pack: start;
	justify-content: flex-start;
	width: 100%;
	padding-left: 12px;
	padding-right: 12px;
	padding-top: 24px;
	padding-bottom: 24px;
	box-sizing: border-box;
	display: flex;
	flex: 1 1 0%;
`
export const PageContainer = styled.div`
	flex-direction: column;
	min-height: 100vh;
	box-sizing: border-box;
	display: flex;
	background: white;
	padding: 24px;
`
export const MainContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	min-width: 260px;
	@media (min-width: 590px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 850px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 1110px) {
		width: 780px;
		max-width: 100%;
	}
`
export const BackContainer = styled.div`
	top: 0;
	position: absolute;
	padding: 20px;
	@media (min-width: 325px) {
		right: 5px;
	}
	@media (min-width: 590px) {
		right: 5px;
	}
	@media (min-width: 850px) {
		left: 240px;
	}
`
export const FormContainer = styled.div`
	max-width: 520px;
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	flex-direction: column;
	-webkit-box-pack: start;
	justify-content: flex-start;
	padding-bottom: 48px;
	display: flex;
	padding-top: 48px;
`
export const FormTitle = styled.div`
	margin-bottom: 32px;
	padding-bottom: 24px;
	box-sizing: border-box;
	border-bottom: 1px solid rgb(213, 220, 227);
	border-color: rgb(213, 220, 227);
	color: black;
	display: block;
	font-size: 25px;
	font-weight: 600;
	line-height: 1.2;
`
export const FormLabel = styled.label<{ labelFontSize?: string }>`
	color: rgba(0, 0, 0, 0.85);
	color: black;
	font-size: ${({ labelFontSize }) => (labelFontSize ? labelFontSize : "16px !important")};
	font-weight: 500;
	padding-right: 6px;
	display: inline-block;
`
export const NavContainer = styled.div`
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	background: white;
	box-shadow: 0px 1px 2px #42424291;
	z-index: 150;
	height: 44px;
	width: 100%;
	top: 0px;
	padding-left: 10px;
`
export const NavLeft = styled.div`
	flex: 1;
	display: flex;
	justify-content: flex-start;
	font-size: 16px;
`
export const NavRight = styled.div`
	flex: 1;
	display: flex;
	justify-content: flex-end;
	margin: 6px;
`
export const BuilderOuterContainer = styled.div`
	width: 100%;
	height: calc(100vh - 44px);
	overflow-x: scroll;
	padding: 10px;
	display: flex;
	flex-direction: row;
	justify-content: center;
`
export const BuilderColumn = styled.div`
	display: block;
	height: 100%;
	width: 330px;
	padding: 10px;
`
export const BuilderColumnInner = styled.div`
	border: solid 1px var(--light-grey);
	border-radius: 4px;
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	position: relative;
`
export const antdTagColors: any = {
	purple: {
		color: "#531dab",
		background: "#f9f0ff",
		borderColor: "#d3adf7"
	},
	cyan: {
		color: "#08979c",
		background: "#e6fffb",
		borderColor: "#87e8de"
	},
	blue: {
		color: "#0958d9",
		background: "#e6f4ff",
		borderColor: "#91caff"
	},
	green: {
		color: "#83cb3c",
		background: "#f2fef3",
		borderColor: "#8ad53f"
	},
	orange: {
		color: "#d46b08",
		background: "#fff7e6",
		borderColor: "#ffd591"
	},
	volcano: {
		color: "#d4380d",
		background: "#fff2e8",
		borderColor: "#ffbb96"
	},
	grey: {
		color: "rgba(0, 0, 0, 0.88)",
		background: "#fafafa",
		borderColor: "#d9d9d9"
	}
}

export const AddButton = styled.div`
	width: 26px;
	height: 26px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	font-size: 12px;
	font-weight: 600;
	border-radius: 999px;
	background: white;
	border: solid 1px var(--light-grey);
	color: var(--dark-grey);
	cursor: pointer;
	box-shadow: 0px 2px 4px var(--v-light-grey);
	transition: 0.5s ease;
	:hover {
		background: var(--secondary-color);
		color: white;
		border: solid 1px var(--secondary-color);
	}
	:focus {
		background: var(--secondary-color);
		color: white;
		border: solid 1px var(--secondary-color);
	}
`
export const Divider = styled.div<{ height?: string; borderOnBottom?: boolean }>`
	height: ${({ height }) => (height ? height : "32px")};
	border-bottom: ${({ borderOnBottom }) =>
		borderOnBottom ? "solid 1px var(--light-grey)" : "solid 0px var(--light-grey)"};
	width: 100%;
`

export const editorIcon = {
	fontSize: 20,
	color: "#B8B8B8",
	paddingTop: 4
}

export const courseEditorStatusColors = {
	complete: "var(--secondary-color)",
	incomplete: "var(--mid-red)",
	none: "var(--mid-grey)"
}
