import React, { useEffect, useState } from "react"
import { Button, Input, Tag } from "antd"
import { AudioFilled, CaretRightOutlined, ExclamationCircleFilled, PauseOutlined } from "@ant-design/icons"
import Paragraph from "antd/lib/typography/Paragraph"
import RecordAudioModal, { useRecordAudioProps } from "./useRecordAudio"
import { LanguageCode } from "learnink-common/lib/constants/language/LanguageCode"
import { selectPermissions, Permissions } from "../../../redux/permissions/permissions.selectors"
import { createStructuredSelector } from "reselect"
import { Resource } from "learnink-common/lib/domains/permissions/Permission.model"
import { connect } from "react-redux"

interface Props {
	textId: string | undefined
	description?: string
	audioFile: string | undefined
	updateAudioFile: (file: string | undefined) => void
	card: any
	languageCode: LanguageCode
	permissions: Permissions
}

const AudioFileInput = ({
	textId,
	description,
	audioFile,
	updateAudioFile,
	card,
	languageCode,
	permissions
}: Props) => {
	const [currentAudioFile, setCurrentAudioFile] = useState<string | undefined>(audioFile)
	const [isValidFile, setIsValidFile] = useState<boolean>(true)
	const [audio, setAudio] = useState<HTMLAudioElement | undefined>(undefined)
	const [audioPlaying, setAudioPlaying] = useState<boolean>(false)
	const { modalIsOpen, setModalIsOpen } = useRecordAudioProps()

	useEffect(() => {
		if (currentAudioFile) {
			const newAudio = new Audio(currentAudioFile)

			const handleError = () => {
				console.error("Error loading audio file:", currentAudioFile)
				setIsValidFile(false)
			}

			newAudio.addEventListener("error", handleError)
			setAudio(newAudio)
			setIsValidFile(true) // Assume valid until proven otherwise

			return () => {
				newAudio.pause()
				newAudio.removeEventListener("error", handleError)
			}
		} else {
			setIsValidFile(true)
		}
	}, [currentAudioFile])

	useEffect(() => {
		setCurrentAudioFile(audioFile)
	}, [audioFile])

	const toggleAudio = () => {
		if (!audio) return

		if (audioPlaying) {
			audio.pause()
			audio.currentTime = 0
		} else {
			audio.play().catch((error) => console.error("Error playing audio:", error))
		}
		setAudioPlaying(!audioPlaying)
	}

	return (
		<>
			{description && (
				<Paragraph style={{ marginBottom: 6, marginTop: 4 }} ellipsis>
					{description}
				</Paragraph>
			)}
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-start",
					alignContent: "center",
					alignItems: "center"
				}}
			>
				<Input.Group compact style={{ marginBottom: 4 }}>
					<Input
						style={{ width: "calc(100% - 70px)" }}
						value={currentAudioFile || ""}
						onChange={(e) => setCurrentAudioFile(e.target.value)}
					/>
					<Button
						type="primary"
						onClick={() => updateAudioFile(currentAudioFile)}
						disabled={audioFile === currentAudioFile || !isValidFile}
					>
						Save
					</Button>
				</Input.Group>
				<Button
					icon={audioPlaying ? <PauseOutlined /> : <CaretRightOutlined />}
					type="primary"
					onClick={toggleAudio}
					shape="circle"
					disabled={!isValidFile || !currentAudioFile}
				/>
				{permissions.canEdit(Resource.enum.VOICE_CLIP_RECORDING) && (
					<span style={{ marginLeft: "5px" }}>
						<Button
							icon={<AudioFilled />}
							type="primary"
							disabled={!textId}
							shape="circle"
							onClick={() => setModalIsOpen(true)}
						/>
						{textId && (
							<RecordAudioModal
								textId={textId}
								card={card}
								languageCode={languageCode}
								modalIsOpen={modalIsOpen}
								setModalIsOpen={setModalIsOpen}
								textToRecord={description}
							/>
						)}
					</span>
				)}
			</div>
			{!isValidFile && (
				<Tag color="var(--dark-red)" icon={<ExclamationCircleFilled />}>
					Audio file not valid
				</Tag>
			)}
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	permissions: selectPermissions
})

export default connect(mapStateToProps)(AudioFileInput)
