import React, { useCallback, useEffect, useMemo, useState } from "react"
import { createStructuredSelector } from "reselect"
import { selectOrgId } from "../../redux/org/org.selectors"
import { connect } from "react-redux"
import UserSearchFilters from "./UserSearchFilters"
import UserSearchTable from "./UserSearchTable"
import { OrderBy, UserSearchParams } from "learnink-common/lib/domains/userManagement/userSearch/UserSearchParams.model"
import { NonEmptyArray } from "learnink-common/lib/types/types"
import { __User, Filter, ModalType, TableColumnLabel } from "./UserSearch.model"
import * as S from "../../components/styles/PageStyles"
import { useApi } from "../../util/useApi"
import Routes from "../../util/Routes"
import _ from "lodash"
import { Drawer } from "antd"
import UserSidePanel from "../../components/management/userTable/UserSidePanel"
import { Placement } from "rc-drawer/lib/Drawer"
import LpModal from "../../components/common/LpModal"
import SendUserNotificationModal from "../../components/management/userModals/SendUserNotificationModal"
import UserCertificateModal from "./UserCertificateModal"
import useUrlQueryString from "../../components/hooks/useUrlQueryString"
import ActionsButton from "../../components/management/userTable/ActionsButton"

const PAGE_ROW_SIZE = 200
const FIXED_CONTENT_ID = "fixedContent" + Math.random()
const CONTENT_BUFFER_HEIGHT = 50
const DEFAULT_FILTERS: Filter[] = [
	{
		inputType: "selectMultiple",
		member: "registration_status",
		operator: "equals",
		typeLabel: "Status",
		values: ["registered"]
	}
]

const DEFAULT_HIDDEN_COLUMN: TableColumnLabel[] = ["First name", "Last name"]

const getFixedContentHeight = () => {
	return document.getElementById(FIXED_CONTENT_ID)?.clientHeight || 0
}

const getTableHeight = () => {
	return Math.round(window.innerHeight - getFixedContentHeight() - CONTENT_BUFFER_HEIGHT)
}

interface Props {
	orgId: string
}

const UserSearchPage = ({ orgId }: Props) => {
	const [urlQuery, setUrlQuery] = useUrlQueryString("filters")
	const [filters, __setFilters] = useState<Filter[]>(urlQuery ? JSON.parse(urlQuery as string) : DEFAULT_FILTERS)
	const [users, setUsers] = useState<__User[]>([])
	const [userCount, setUserCount] = useState<number>(0)

	const [allUsersSelected, setAllUsersSelected] = useState<boolean>(false)
	const [selectedUserIds, setSelectedUserIds] = useState<string[]>([])
	const [unselectedUserIds, setUnselectedUserIds] = useState<string[]>([])

	const [modalType, setModalType] = useState<ModalType | undefined>(undefined)
	const [showDrawer, setShowDrawer] = useState<boolean>(false)
	const [selectedUser, setSelectedUser] = useState<__User | undefined>(undefined)

	const [errors, setErrors] = useState<string | undefined>(undefined)

	const [offset, setOffset] = useState<number>(0)
	const [limit] = useState<number>(PAGE_ROW_SIZE)

	const [orderBy, setOrderBy] = useState<OrderBy | undefined>(undefined)
	const [tableHeight, setTableHeight] = useState<number>(getTableHeight())

	const setFiltersAndUrl = (filters: Filter[]) => {
		__setFilters(filters)
		if (filters) {
			;(setUrlQuery as (val: string) => void)(JSON.stringify(filters))
		}
	}

	const userSearchParams: UserSearchParams = useMemo(() => {
		return {
			orgId,
			searchFilters: filters.length > 0 ? (filters as NonEmptyArray<Filter>) : undefined,
			orderBy,
			limit,
			offset
		}
	}, [orgId, filters, filters.length, orderBy, limit, offset])

	const handleSelectedUsers = {
		addAll: () => {
			setAllUsersSelected(true)
			setSelectedUserIds([])
			setUnselectedUserIds([])
		},
		removeAll: () => {
			setAllUsersSelected(false)
			setSelectedUserIds([])
			setUnselectedUserIds([])
		},
		addUserId: (userId: string) => {
			setSelectedUserIds([...selectedUserIds, userId])
			setUnselectedUserIds(_.without(unselectedUserIds, userId))
		},
		removeUserId: (userId: string) => {
			setUnselectedUserIds([...unselectedUserIds, userId])
			setSelectedUserIds(_.without(selectedUserIds, userId))
		}
	}

	const resetTable = () => {
		setUsers([])
		setUserCount(0)
		setOffset(0)
	}

	useEffect(() => {
		resetTable()
	}, [orgId, filters, filters.length, orderBy, limit])

	const { setApiRequest: getUsersRequest } = useApi<UserSearchParams, __User[]>()
	const { setApiRequest: countUsersRequest } = useApi<UserSearchParams, { count: number | undefined }>()

	const api = {
		countUsers: () => {
			countUsersRequest({
				route: Routes.userCount,
				urlParams: { orgId },
				payload: userSearchParams,
				onSuccess: (data) => {
					setUserCount(data?.count || 0)
				}
			})
		},
		getUsers: useCallback(async () => {
			const updateTable = (newUsers: __User[]) => {
				setUsers(users.concat(newUsers))
				setErrors(undefined)
				setOffset(limit + offset)
			}

			getUsersRequest({
				route: Routes.userSearch,
				urlParams: { orgId },
				payload: userSearchParams,
				onSuccess: (users) => {
					if (offset === 0) api.countUsers()
					updateTable(users)
				},
				onError: (error) => {
					if (error) setErrors(error as string)
					console.log({ error })
				}
			})
		}, [limit, offset, orgId, filters, filters.length, userSearchParams, userSearchParams?.searchFilters?.length])
	}

	useEffect(() => {
		if (offset === 0) {
			void api.getUsers()
		}
	}, [offset, api.getUsers])

	const handleTableHeight = () => setTableHeight(getTableHeight())

	const resizeTableHeight = () => {
		window.addEventListener("resize", handleTableHeight)
		return () => {
			window.removeEventListener("resize", handleTableHeight)
		}
	}

	useEffect(resizeTableHeight, [])

	const sidePanel = true // TODO

	const openMessageModal = (user: __User) => {
		setSelectedUser(user.appDownloaded ? user : undefined)
		setModalType("notification")
	}

	const openCertificateModal = (e: Event, user: __User) => {
		e.stopPropagation()
		setSelectedUser(user)
		setModalType("certificate")
	}

	const openDrawer = (user: __User) => {
		if (sidePanel) {
			setSelectedUser(user)
			setShowDrawer(true)
		}
	}

	const userDrawerProps = {
		title: "User settings",
		placement: "right" as Placement,
		onClose: () => {
			setShowDrawer(false)
		},
		open: showDrawer
	}

	const RightOptions = (
		<ActionsButton
			data={users}
			allUsersSelected={allUsersSelected}
			selectedUserIds={selectedUserIds}
			unselectedUserIds={unselectedUserIds}
			removeUsersFromList={(_: string[]) => {}} //	TODO
			actions={["notify", "editUserGroups", "editLearningPath", "archive", "unarchive", "delete", "export"]}
			userSearchParams={userSearchParams}
			orgId={orgId}
			userCount={userCount}
			serverMode={true}
		/>
	)

	return (
		<S.FixedPageDiv>
			<S.PaddedDivLarge id={FIXED_CONTENT_ID}>
				{errors && <div>{errors}</div>}
				<UserSearchFilters setFilters={setFiltersAndUrl} filters={filters} />
			</S.PaddedDivLarge>

			<UserSearchTable
				users={users}
				fetchMoreUsers={api.getUsers}
				orderBy={orderBy}
				handleChangeOrderBy={setOrderBy}
				userCount={userCount}
				height={tableHeight}
				hiddenColumns={DEFAULT_HIDDEN_COLUMN}
				allUsersSelected={allUsersSelected}
				selectedUserIds={selectedUserIds}
				unselectedUserIds={unselectedUserIds}
				handleSelectedUsers={handleSelectedUsers}
				openDrawer={openDrawer}
				openMessageModal={openMessageModal}
				rightOptions={RightOptions}
			/>

			{modalType === "notification" && (
				<LpModal isShowingModal={true} hideModal={() => setModalType(undefined)} maxWidth="600px">
					<SendUserNotificationModal
						usersData={[selectedUser]}
						cancelModal={() => setModalType(undefined)}
						getUsers={() => {}}
					/>
				</LpModal>
			)}

			{modalType === "certificate" && selectedUser && (
				<UserCertificateModal orgId={orgId} user={selectedUser} setModalType={setModalType} />
			)}

			<Drawer {...userDrawerProps}>
				<UserSidePanel
					closemodal={() => {
						setShowDrawer(false)
					}}
					// @ts-ignore
					userData={selectedUser}
				/>
			</Drawer>
		</S.FixedPageDiv>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId
})

// @ts-ignore
export default connect(mapStateToProps)(UserSearchPage)
