import React, { useCallback, useEffect, useRef, useState } from "react"
import { DownOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import { Button, Dropdown, Menu, Tooltip } from "antd"
import { CSVLink } from "react-csv"
import {
	MAX_USER_SEARCH_PARAMS_LIMIT,
	UserSearchParams
} from "learnink-common/lib/domains/userManagement/userSearch/UserSearchParams.model"
import LpModal from "../../common/LpModal"
import SendUserNotificationModal from "../userModals/SendUserNotificationModal"
import EditUserGroupsModal from "../userModals/EditUserGroupsModal"
import ArchiveUsersModal from "../userModals/ArchiveUsersModal"
import DeleteUsersModal from "../userModals/DeleteUsersModal"
import EditLearningPathModal from "../userModals/EditLearningPathModal"
import { __User, UserIdsSelectedProps } from "../../../pages/userSearch/UserSearch.model"
import { useApi } from "../../../util/useApi"
import Routes from "../../../util/Routes"
import _ from "lodash"

export type Action = "notify" | "editUserGroups" | "editLearningPath" | "archive" | "unarchive" | "delete" | "export"

type CSVRow = Partial<__User> & { referrer?: string | undefined }

interface Handler {
	selectedUsers: () => __User[]
}

type ServerModeProps = {
	selectedRowIds?: undefined
	data: __User[]
	removeUsersFromList: (userIds: string[]) => void
	actions: Action[]
	userSearchParams: UserSearchParams
	orgId: string
	userCount: number
	/** In the world of infinite scroll tables, we won't typically have all user ids on the client, so we need to generate batch actions fully on the server... */
	serverMode: true
} & UserIdsSelectedProps

type ClientModeProps = {
	selectedRowIds: { [key: string]: string }
	data: __User[]
	removeUsersFromList: (userIds: string[]) => void
	actions: Action[]
	serverMode?: false
	allUsersSelected?: undefined
	selectedUserIds?: undefined
	unselectedUserIds?: undefined
	userSearchParams?: undefined
	orgId?: undefined
	userCount?: undefined
}

const ActionsButton = ({
	selectedRowIds,
	data,
	removeUsersFromList,
	actions,
	selectedUserIds,
	unselectedUserIds,
	allUsersSelected,
	userSearchParams,
	orgId,
	userCount,
	serverMode
}: ServerModeProps | ClientModeProps) => {
	const [modalSelect, setModalSelect] = useState<Action | undefined>(undefined)
	const [usersToDownload, setUsersToDownload] = useState<Partial<__User>[]>([])
	const csvDownloadRef = useRef<CSVLink & HTMLAnchorElement & { link?: HTMLAnchorElement }>(null) // used for referring to CSVLink component below...
	const { setApiRequest: getUsersRequest, data: getUsersResult } = useApi<UserSearchParams, __User[]>()

	const maxUserSearchParams = { ...userSearchParams } as UserSearchParams
	maxUserSearchParams.limit = MAX_USER_SEARCH_PARAMS_LIMIT
	maxUserSearchParams.offset = 0

	const requestParams = {
		route: Routes.userSearch,
		urlParams: { orgId: orgId as string },
		payload: maxUserSearchParams
	}

	const getUsers = () => {
		getUsersRequest(requestParams)
	}

	const filterUnselected = (user: __User) => !unselectedUserIds?.includes(user.id)

	const downloadCsv = () => {
		if (csvDownloadRef.current === null) return
		setTimeout(() => {
			csvDownloadRef.current?.link?.click()
		}, 200)
	}

	const userToCsv = ({
		appDownloaded,
		userGroups,
		coursesStarted,
		coursesCompleted,
		actionList,
		shareRequestSenderIds,
		shareRequestSenders,
		...rest
	}: __User): CSVRow => {
		const csvRow: CSVRow = rest

		csvRow.referrer = _.uniqBy(shareRequestSenders, (s) => s.id)
			?.map((sender) => `(id: ${sender.id}, name: ${sender.name})`)
			.join(";")

		return csvRow
	}

	const formatForCsv = () => {
		if (allUsersSelected) {
			getUsersRequest({
				...requestParams,
				onSuccess: (users) => {
					setUsersToDownload(users.filter(filterUnselected).map(userToCsv))
				}
			})
			return
		}
		setUsersToDownload(users.map(userToCsv))
	}

	const client: Handler = {
		selectedUsers: () => {
			return Object.keys(selectedRowIds || [])
				.map((i) => parseInt(i))
				.map((i) => (data || [])[i])
		}
	}

	const server: Handler = {
		selectedUsers: useCallback(() => {
			if (!allUsersSelected) {
				return data.filter((x) => (selectedUserIds || []).includes(x.id)).filter(filterUnselected)
			}

			return (getUsersResult || []).filter(filterUnselected)
		}, [allUsersSelected, selectedUserIds, unselectedUserIds, getUsersResult])
	}

	const getHandler = (): Handler => {
		return serverMode ? server : client
	}

	const users = getHandler().selectedUsers()
	const registered = users.filter((x) => x.registrationStatus === "registered")
	const archived = users.filter((x) => x.registrationStatus === "archived")
	const notifiable = users.filter((x) => !!x.appDownloaded)

	useEffect(downloadCsv, [usersToDownload])

	const showClientActions = !serverMode && (users || []).length > 0
	const showServerActions = serverMode && ((selectedUserIds || []).length > 0 || allUsersSelected)

	if (!showClientActions && !showServerActions) {
		return <></>
	}

	const menuItems: { key: Action; label: React.ReactNode }[] = [
		{
			key: "notify",
			label: <div onClick={() => setModalSelect("notify")}>Notify users</div>
		},
		{
			key: "editUserGroups",
			label: <div onClick={() => setModalSelect("editUserGroups")}>Edit user groups</div>
		},
		{
			key: "editLearningPath",
			label: <div onClick={() => setModalSelect("editLearningPath")}>Edit learning path</div>
		},
		{
			key: "archive",
			label: <div onClick={() => setModalSelect("archive")}>Archive users</div>
		},
		{
			key: "unarchive",
			label: <div onClick={() => setModalSelect("unarchive")}>Unarchive users</div>
		},
		{
			key: "delete",
			label: <div onClick={() => setModalSelect("delete")}>Delete users</div>
		},
		{
			key: "export",
			label: <div onClick={formatForCsv}>Export data</div>
		}
	]

	const menu = <Menu items={menuItems.filter((item) => actions.includes(item.key))} />

	return (
		<>
			<Dropdown overlay={menu} placement="bottomRight">
				<Button type="primary" shape="round">
					Actions <TooManyRowsText serverMode={serverMode} userCount={userCount} />
					<DownOutlined />
				</Button>
			</Dropdown>

			<CSVLink
				ref={csvDownloadRef}
				data={usersToDownload}
				filename={`user_list_${Date.now()}.csv`}
				style={{ display: "none" }}
				target="_blank"
			/>

			<LpModal
				isShowingModal={!!(modalSelect && !["editLearningPath", "delete"].includes(modalSelect))}
				hideModal={() => setModalSelect(undefined)}
				maxWidth="600px"
			>
				{modalSelect === "notify" && (
					<SendUserNotificationModal
						usersData={notifiable}
						cancelModal={() => setModalSelect(undefined)}
						getUsers={getUsers}
					/>
				)}
				{modalSelect === "editUserGroups" && (
					<EditUserGroupsModal
						usersData={users}
						cancelModal={() => setModalSelect(undefined)}
						getUsers={getUsers}
					/>
				)}
				{modalSelect === "archive" && (
					<ArchiveUsersModal
						usersData={registered}
						archive
						cancelModal={() => setModalSelect(undefined)}
						removeUsersFromList={() => {}}
						getUsers={getUsers}
					/>
				)}
				{modalSelect === "unarchive" && (
					<ArchiveUsersModal
						usersData={archived}
						archive={false}
						cancelModal={() => setModalSelect(undefined)}
						removeUsersFromList={removeUsersFromList}
						getUsers={getUsers}
					/>
				)}
			</LpModal>
			{modalSelect === "editLearningPath" && (
				<EditLearningPathModal
					usersData={users}
					closeModal={() => setModalSelect(undefined)}
					getUsers={getUsers}
				/>
			)}
			{modalSelect === "delete" && (
				<DeleteUsersModal usersData={users} closeModal={() => setModalSelect(undefined)} getUsers={getUsers} />
			)}
		</>
	)
}

export default ActionsButton

function TooManyRowsText({
	serverMode,
	userCount
}: {
	serverMode: boolean | undefined
	userCount: number | undefined
}) {
	const title =
		"Your filters have returned too many results to perform actions on the whole list. You can add more filters to limit the size"

	const hasTooManyRows = serverMode && (userCount || 0) > MAX_USER_SEARCH_PARAMS_LIMIT
	const TooManyRowsTooltip = (
		<Tooltip title={title}>
			<QuestionCircleOutlined />
		</Tooltip>
	)
	const tooManyRowsText = (
		<>
			{" "}
			({MAX_USER_SEARCH_PARAMS_LIMIT} row limit){TooManyRowsTooltip}
		</>
	)

	return hasTooManyRows ? tooManyRowsText : <></>
}
