import React, { useState } from "react"
import { Modal } from "antd"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import * as S from "../../styles/PageStyles"
import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import BigOnOffButton from "../../common/BigOnOffButton"
import { faCalendar, faFlag } from "@fortawesome/free-solid-svg-icons"
import { selectLearningPathById, selectLearningPaths, selectRoleTypeUserGroups } from "../../../redux/org/org.selectors"
import { UnlockTrigger } from "learnink-common/lib/domains/sequences/Sequence.model"
import { LearningPathContent } from "../../../pages/learningPaths/utils/LearningPathContent"

interface Props {
	learningPath: LearningPath
	onCancel: () => void
	updateLearningPath: (learningPath: LearningPath) => void
}

const EditUnlockTrigger = ({ learningPath, onCancel, updateLearningPath }: Props) => {
	const [unlockTrigger, setUnlockTrigger] = useState<UnlockTrigger>(
		new LearningPathContent(learningPath).getUnlockTrigger()
	)

	const recalculateLearningPath = () => {
		const updatedLearningPath = { ...learningPath }
		learningPath.sequences.forEach((sequence, sequenceIndex) => {
			updatedLearningPath.sequences[sequenceIndex].unlockTrigger =
				sequenceIndex === 0 ? "start_learning_path" : unlockTrigger
			updatedLearningPath.sequences[sequenceIndex].staggerProgress = false
			updatedLearningPath.sequences[sequenceIndex].daysPerCourse = undefined
			if (unlockTrigger === "finish_previous") {
				const unlockDelayDays = 0
				updatedLearningPath.sequences[sequenceIndex].unlockDelayDays = 0
				const numDays = sequence.deadlineDay - unlockDelayDays + 1
				updatedLearningPath.sequences[sequenceIndex].numDays = numDays
			} else {
				const unlockDelayDays =
					sequenceIndex === 0 ? 0 : learningPath.sequences[sequenceIndex - 1].deadlineDay + 1
				updatedLearningPath.sequences[sequenceIndex].unlockDelayDays = unlockDelayDays
				updatedLearningPath.sequences[sequenceIndex].numDays = sequence.deadlineDay - unlockDelayDays + 1
			}
		})
		updateLearningPath(updatedLearningPath)
	}

	return (
		<Modal
			okText="Save"
			onCancel={onCancel}
			title="Edit unlocking setting"
			open={true}
			centered
			width={500}
			onOk={() => recalculateLearningPath()}
		>
			<S.FlexBoxStartRow>
				<BigOnOffButton
					key="finish_previous"
					isOn={unlockTrigger === "finish_previous"}
					color="purple"
					text="Unlock next when previous is complete"
					faIcon={faFlag}
					onClick={() => setUnlockTrigger("finish_previous")}
				/>
				<BigOnOffButton
					key="start_learning_path"
					isOn={unlockTrigger === "start_learning_path"}
					color="cyan"
					text="Unlock next after previous deadline"
					faIcon={faCalendar}
					onClick={() => setUnlockTrigger("start_learning_path")}
				/>
			</S.FlexBoxStartRow>
		</Modal>
	)
}
const mapStateToProps = createStructuredSelector({
	learningPaths: selectLearningPaths,
	learningPathById: selectLearningPathById,
	roleUserGroups: selectRoleTypeUserGroups
})

// @ts-ignore
export default connect(mapStateToProps)(EditUnlockTrigger)
