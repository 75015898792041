import React from "react"
import styled from "styled-components"
import moment from "moment"
import { Alert, Tag, Typography } from "antd"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import { faBook, faCalendar, faClock, faFlag } from "@fortawesome/free-solid-svg-icons"

import * as S from "../../styles/PageStyles"
import { selectOrgCourseCards } from "../../../redux/org/org.selectors"
import InfographicSection from "../../common/InfographicSection"
import { LearningPathContent, triggerLabels } from "../../../pages/learningPaths/utils/LearningPathContent"
import { toHoursAndMinutesShortText } from "../../../util/formatters"
import EditableSetting from "../../common/EditableSetting"
import { CourseCards } from "../../../util/model/OrgSelectors"
import { ModalTypes } from "../../../pages/learningPaths/LearningPathBuilder"
import { DAY_MONTH_FORMAT } from "learnink-common/lib/constants/constants"

const { Title } = Typography

const ColumnInner = styled.div`
	width: 100%;
	flex: 1;
	border: solid 2px var(--v-light-grey);
	border-radius: 20px;
	overflow: hidden;
	margin-bottom: 20px;
	position: relative;
`

interface Props {
	learningPath: LearningPath
	courseCards: CourseCards
	setModalType: React.Dispatch<React.SetStateAction<ModalTypes>>
	learningPathWarnings: string[]
}

const GuideColumn = ({ learningPath, courseCards, setModalType, learningPathWarnings }: Props) => {
	const totalTrainingTime = toHoursAndMinutesShortText(
		new LearningPathContent(learningPath).getTotalTrainingTime(courseCards)
	)
	const learningPathStatus = new LearningPathContent(learningPath).getStatus()
	const unlockTrigger = new LearningPathContent(learningPath).getUnlockTrigger()

	const statusMessage = {
		ACTIVE: { tag: "ACTIVE", tagColor: "green" },
		PAST: { tag: "PAST", tagColor: undefined },
		ARCHIVED: { tag: "ARCHIVED", tagColor: undefined },
		SCHEDULED: {
			tag: `Live on ${moment(learningPath.startAt).format(DAY_MONTH_FORMAT)}`,
			tagColor: "blue"
		}
	}

	return (
		<S.BuilderColumn style={{ width: 280 }}>
			<S.BuilderColumnInner style={{ borderWidth: 0, padding: 10 }}>
				<EditableSetting
					component={
						<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
							<Title level={5} style={{ margin: 0, marginRight: 10 }}>
								Status:
							</Title>
							<Tag color={statusMessage[learningPathStatus].tagColor}>
								{statusMessage[learningPathStatus].tag}
							</Tag>
						</div>
					}
				/>
				{learningPath.sequences.length > 1 && (
					<EditableSetting
						title={triggerLabels[unlockTrigger].title}
						subtitle={triggerLabels[unlockTrigger].label}
						iconBubbleColor={triggerLabels[unlockTrigger].color}
						faIcon={triggerLabels[unlockTrigger].title === "Continuous" ? faFlag : faCalendar}
						isEditable={!learningPath.sequences[0].classroom}
						onClick={() => setModalType("editUnlockTrigger")}
					/>
				)}
				<Title level={5}>Content stats</Title>
				<ColumnInner>
					<InfographicSection
						faIcon={faBook}
						title={`${new LearningPathContent(learningPath).getNumModuleStages(courseCards)}`}
						subtitle="modules"
						key="modules"
					/>
					<InfographicSection
						faIcon={faCalendar}
						title={`${new LearningPathContent(learningPath).getLengthInDays()}`}
						subtitle="days"
						key="days"
					/>
					<InfographicSection
						faIcon={faClock}
						title={totalTrainingTime}
						subtitle="of training"
						key="time"
						isLast
					/>
				</ColumnInner>
				{learningPathWarnings.map((message, index) => (
					<Alert key={index} message={message} style={{ marginBottom: 20 }} type="warning" />
				))}
			</S.BuilderColumnInner>
		</S.BuilderColumn>
	)
}

const mapStateToProps = createStructuredSelector({
	courseCards: selectOrgCourseCards
})

export default connect(mapStateToProps)(GuideColumn)
