import React, { useEffect, useState } from "react"
import { NonEmptyArray } from "learnink-common/lib/types/types"
import { Button, Dropdown, Menu } from "antd"
import {
	InputType,
	Filter,
	FilterParams,
	Option,
	TableColumnLabel,
	UserGroupLabels,
	FilterableMember
} from "./UserSearch.model"
import ActiveFilter from "./ActiveFilter"
import { FilterFilled } from "@ant-design/icons"
import InactiveFilter from "./InactiveFilter"
import { createStructuredSelector } from "reselect"
import { selectLearningPaths, selectOrgId, selectOrgUserGroupNamesMap } from "../../redux/org/org.selectors"
import { connect } from "react-redux"
import { AgeBracket, RegistrationStatus, Sex } from "learnink-common/lib/domains/userManagement/users/User.model"
import { UserOptions } from "learnink-common/lib/domains/userManagement/userSearch/UserSearchParams.model"
import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import {
	Member,
	AgeBracketFilter,
	DateFilter,
	RegistrationStatusFilter,
	SexFilter,
	StringArrayFilter,
	StringFilter,
	Operator,
	BooleanFilter
} from "learnink-common/lib/domains/userManagement/userSearch/SearchFilter.model"
import { EnumValues, Values } from "zod"
import { useApi } from "../../util/useApi"
import Routes from "../../util/Routes"

interface Props {
	orgId: string
	setFilters: (filters: Filter[]) => void
	filters: Filter[]
	userGroups: UserGroupLabels
	learningPaths: LearningPath[] | undefined
}

function UserSearchFilters({ orgId, setFilters, filters, userGroups, learningPaths }: Props) {
	const [filterParams, setFilterParams] = useState<FilterParams | undefined>(undefined)
	const [columnLabel, setColumnLabel] = useState<TableColumnLabel | undefined>(undefined)

	const { setApiRequest: optionsRequest, data: apiOptions } = useApi<undefined, UserOptions>()

	useEffect(() => {
		optionsRequest({ route: Routes.userSearchOptions, urlParams: { orgId } })
	}, [])

	const options = {
		status: RegistrationStatus.options.map(valueToOption),
		sex: Sex.options.map(valueToOption),
		age: AgeBracket.options.map(valueToOption),
		country: (apiOptions?.countries || []).filter((x) => x).map(valueToOption),
		region: (apiOptions?.regions || []).filter((x) => x).map(valueToOption),
		learningPath: (learningPaths || []).map((l) => ({ value: l.id, label: l.internalName })),
		downloadedApp: [{ value: "true", label: "is downloaded" }], // We don't actually map this to "true"/"false" in the backend, but "exists" or "not exists" from the operator, so the boolean value is unused
		sender: (apiOptions?.shareRequestSenders || []).map(({ id, name }) => ({ value: id, label: name })),
		userGroup: Object.entries(userGroups).map(([key, value]) => ({ value: key, label: value }))
	}

	const DEFAULT_FILTER_PARAMS_BY_COLUMN_LABEL: { [key in FilterableMember]: FilterParams } = {
		"Full name": getFilterParams(Member.enum.full_name, StringFilter, "stringInput"),
		Status: getFilterParams(
			Member.enum.registration_status,
			RegistrationStatusFilter,
			"selectMultiple",
			options.status
		),
		Referrer: getFilterParams(
			Member.enum.share_request_sender_ids,
			StringArrayFilter,
			"selectMultiple",
			options.sender
		),
		"User groups": getFilterParams(Member.enum.user_groups, StringArrayFilter, "selectMultiple", options.userGroup),
		"Learning path": getFilterParams(
			Member.enum.learningpath_id,
			StringFilter,
			"selectMultiple",
			options.learningPath
		),
		"First name": getFilterParams(Member.enum.first_name, StringFilter, "stringInput"),
		"Last name": getFilterParams(Member.enum.last_name, StringFilter, "stringInput"),
		"Last active": getFilterParams(Member.enum.last_active, DateFilter, "datePicker"),
		"Custom ID": getFilterParams(Member.enum.custom_id, StringFilter, "stringInput"),
		"User ID": getFilterParams(Member.enum.user_id, StringFilter, "stringInput"),
		"Phone number": getFilterParams(Member.enum.phone_number, StringFilter, "stringInput"),
		Sex: getFilterParams(Member.enum.sex, SexFilter, "selectMultiple", options.sex),
		Country: getFilterParams(Member.enum.country, StringFilter, "selectMultiple", options.country),
		Region: getFilterParams(Member.enum.region, StringFilter, "selectMultiple", options.region),
		Age: getFilterParams(Member.enum.age_bracket, AgeBracketFilter, "selectMultiple", options.age),
		"Downloaded app": getFilterParams(
			Member.enum.app_downloaded,
			BooleanFilter,
			"selectMultiple",
			options.downloadedApp
		),
		Registered: getFilterParams(Member.enum.registered_at, DateFilter, "datePicker")
	}

	const AddNewFilterButton = () => {
		const ColumnFilterButton = ({ columnLabel }: { columnLabel: FilterableMember }) => {
			return (
				<div
					onClick={() => {
						setFilterParams(DEFAULT_FILTER_PARAMS_BY_COLUMN_LABEL[columnLabel])
						setColumnLabel(columnLabel)
					}}
				>
					{columnLabel}
				</div>
			)
		}

		const filterMenu = FilterableMember.options.map((label) => ({
			label: <ColumnFilterButton columnLabel={label} />,
			key: label.replaceAll(" ", "-")
		}))

		return (
			<Dropdown overlay={<Menu items={filterMenu} />} trigger={["click"]}>
				<Button icon={<FilterFilled />}>Add filter</Button>
			</Dropdown>
		)
	}

	const removeFilter = (index: number) => {
		return () => {
			const newFilters: NonEmptyArray<Filter> = [...(filters as NonEmptyArray<Filter>)]
			newFilters.splice(index, 1)
			setFilters(newFilters)
		}
	}

	return (
		<>
			{(filters || []).map((filter, index) => {
				return (
					<InactiveFilter
						key={index}
						filter={filter}
						isFirstFilter={index === 0}
						removeFilter={removeFilter(index)}
					/>
				)
			})}

			{filterParams && columnLabel && (
				<ActiveFilter
					filterParams={filterParams}
					columnLabel={columnLabel}
					filters={filters}
					setFilterParams={setFilterParams}
					setFilters={setFilters}
				/>
			)}

			{!filterParams && <AddNewFilterButton />}
		</>
	)
}

/** Just some Zod typing for the things we are going to use, so we don't have to type the whole thing... */
interface FilterSchema {
	shape: {
		member: {
			enum: Values<EnumValues> // If we could type this completely so that FILTER_BY_COLUMN_LABEL is properly typed, that would be nice...
		}
		operator: {
			options: Operator[]
		}
	}
}

function getFilterParams(
	key: Member,
	filterSchema: FilterSchema,
	inputType: InputType,
	options?: Option[]
): FilterParams {
	const member = filterSchema.shape.member.enum[key]
	if (!member)
		throw new Error(
			`No member found for filter, backend definition may have changed. key: ${key} filter: ${filterSchema}`
		)

	const operators = filterSchema.shape.operator.options.filter((o) =>
		!IGNORED_INPUT_TYPE_OPERATORS[inputType].includes(o)
	)
	return {
		member: member as Member,
		operators: operators,
		selectedOperator: operators[0],
		inputType,
		options
	}
}

const IGNORED_INPUT_TYPE_OPERATORS: { [key in InputType]: Operator[] } = {
	datePicker: [Operator.enum.in, Operator.enum.notIn],
	numberInput: [Operator.enum.in, Operator.enum.notIn],
	stringInput: [Operator.enum.in, Operator.enum.notIn],
	selectMultiple: []
}

function valueToOption(value: string): Option {
	return { value, label: value }
}

export function operatorRequiresValue(operator: Operator): boolean {
	return !(
		[Operator.enum.hasValue, Operator.enum.hasNoValue, Operator.enum.empty, Operator.enum.notEmpty] as Operator[]
	).includes(operator)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	userGroups: selectOrgUserGroupNamesMap,
	learningPaths: selectLearningPaths
})

// @ts-ignore
export default connect(mapStateToProps)(UserSearchFilters)
