import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import PanelContainer from "../editor/panels/common/PanelContainer"
import { Avatar, Button, Segmented, Select } from "antd"
import { LanguageCode } from "learnink-common/lib/constants/language/LanguageCode"
import { CardModel, DEFAULT_LANGUAGES, DEFAULT_VOICE_ID } from "../../../util/model/Cards"
import { Card, CardType } from "learnink-common/lib/domains/cards/Card.model"
import { LessonCard } from "learnink-common/lib/domains/cards/lessons/LessonCard.model"
import { MultipleChoiceQuestionCard } from "learnink-common/lib/domains/cards/questions/multipleChoiceQuestion/MultipleChoiceQuestionCard.model"
import { MultipleChoiceSurveyCard } from "learnink-common/lib/domains/cards/lessons/survey/SurveyCard.model"
import { updateLessonCard, updateQuestion } from "../../../redux/courses/courses.actions"
import AudioFileInput from "./AudioFileInput"
import { courseEditorStatusColors } from "../../styles/PageStyles"
import { createStructuredSelector } from "reselect"
import { selectOrgId } from "../../../redux/org/org.selectors"
import TextToSpeech from "./TextToSpeech"
import { selectPermissions, Permissions } from "../../../redux/permissions/permissions.selectors"
import { Resource } from "learnink-common/lib/domains/permissions/Permission.model"
import { toFormattedLanguage as format } from "../../../util/formatters"
import { TextCard } from "learnink-common/lib/domains/cards/lessons/text/TextCard.model"
import { CallOutCard } from "learnink-common/lib/domains/cards/lessons/callOut/CallOutCard.model"
import _ from "lodash"
import styled from "styled-components"
import { handleMouseClick } from "../../../util/handleMouseClick"

interface Props {
	orgId: string
	card: Card
	updateLessonCard: (updateParams: LessonCard) => void
	updateQuestion: (updateParams: MultipleChoiceQuestionCard) => void
	permissions: Permissions
}

const voiceId = DEFAULT_VOICE_ID

interface Option {
	value: string
	label: string
}

const S = {
	LanguageOptions: styled.div`
		overflow: auto;
		white-space: nowrap;
	`,
	LanguageOption: styled.div`
		padding-top: 4px;
	`,
	LanguageOptionLabel: styled.div`
		max-width: 60px;
		overflow-x: hidden;
		text-overflow: ellipsis;
	`,
	SelectLanguage: styled.div`
		margin-top: 10px;
		div {
			width: 100%;
		}
	`
}

const VoicePanel = ({ orgId, card, updateLessonCard, updateQuestion, permissions }: Props) => {
	const cardVoiceClips = card.voiceClips || []
	const storedLanguages: LanguageCode[] = cardVoiceClips
		.map((clip) => clip.languageCode as LanguageCode)
		.filter((l) => l)
	const uniqueLanguages = _.uniq([...DEFAULT_LANGUAGES, ...storedLanguages])

	const [languages, setLanguages] = useState<LanguageCode[]>(uniqueLanguages)
	const [clickedLanguage, setClickedLanguage] = useState<LanguageCode | undefined>(undefined)
	const [languageCode, setLanguageCode] = useState<LanguageCode>(languages[0])

	const [showSelectLanguage, setShowSelectLanguage] = useState<boolean>(false)

	const savedAudioFile =
		cardVoiceClips.filter((item) => item.languageCode === languageCode).length > 0
			? cardVoiceClips.filter((item) => item.languageCode === languageCode)[0].fileUrl
			: undefined

	const getLanguageOptionId = (languageCode: LanguageCode) => `language-code-${languageCode}`

	const addLanguage = (languageCode: LanguageCode) => {
		if (!languages.includes(languageCode)) {
			const newLanguages = [...languages]
			newLanguages.push(languageCode)

			setLanguages(newLanguages)
			setClickedLanguage(languageCode)
		}
		setShowSelectLanguage(false)
	}

	const clickOnLanguageOption = () => {
		if (!clickedLanguage) return

		const element = document.getElementById(getLanguageOptionId(clickedLanguage))
		if (element) handleMouseClick(element)
	}

	useEffect(clickOnLanguageOption, [clickedLanguage])

	const voiceClipPartial = { voiceId, languageCode, orgId } as const

	const updateVoiceClip = (audioFile: string | undefined) => {
		const text = getTextFromCard(card)
		let newVoiceClips = cardVoiceClips.filter((item) => item.languageCode !== languageCode)
		if (audioFile && text) {
			newVoiceClips = [...newVoiceClips, { text, ...voiceClipPartial, fileUrl: audioFile }]
		}
		if (card.type === "multipleChoiceQuestion") {
			updateQuestion({ ...card, voiceClips: newVoiceClips })
		}
		if (card.type !== "multipleChoiceQuestion") {
			updateLessonCard({ ...card, voiceClips: newVoiceClips })
		}
	}

	const updateSurveyOptionVoiceClip = (audioFile: string | undefined, index: number) => {
		const newCard = {
			...card
		} as MultipleChoiceSurveyCard
		let optionVoiceClips = newCard.surveyOptions?.[index].voiceClips || []
		optionVoiceClips = optionVoiceClips.filter((item) => item.languageCode !== languageCode)
		if (audioFile) {
			optionVoiceClips = [
				...optionVoiceClips,
				{
					...voiceClipPartial,
					text: newCard.surveyOptions?.[index].text as string,
					fileUrl: audioFile
				}
			]
		}
		if (newCard.surveyOptions?.[index]) {
			newCard.surveyOptions[index].voiceClips = optionVoiceClips
		}

		updateLessonCard(newCard)
	}

	const updateQuestionAnswerVoiceClip = (audioFile: string | undefined, index: number) => {
		const newCard = {
			...card
		} as MultipleChoiceQuestionCard
		let optionVoiceClips = newCard.answerOptions?.[index].voiceClips || []
		optionVoiceClips = optionVoiceClips.filter((item) => item.languageCode !== languageCode)
		if (audioFile) {
			optionVoiceClips = [
				...optionVoiceClips,
				{
					...voiceClipPartial,
					text: newCard.answerOptions?.[index].text as string,
					fileUrl: audioFile
				}
			]
		}
		if (newCard.answerOptions?.[index]) {
			newCard.answerOptions[index].voiceClips = optionVoiceClips
		}

		updateQuestion(newCard)
	}
	const LanguageOptions = (
		<S.LanguageOptions>
			<Segmented
				options={languages.map((languageCode, key) => {
					const voiceClipStatus = new CardModel(card).getVoiceClipButtonStatus(languageCode, voiceId)
					const avatarStyle = { backgroundColor: courseEditorStatusColors[voiceClipStatus.status] }
					return {
						label: (
							<S.LanguageOption key={key} id={getLanguageOptionId(languageCode)}>
								<Avatar style={avatarStyle}>{languageCode}</Avatar>
								<S.LanguageOptionLabel>{format(languageCode)}</S.LanguageOptionLabel>
							</S.LanguageOption>
						),
						value: languageCode
					}
				})}
				// @ts-ignore
				onChange={setLanguageCode}
			/>
		</S.LanguageOptions>
	)

	const SelectLanguage = (
		<S.SelectLanguage>
			{!showSelectLanguage && (
				<Button
					type="ghost"
					size="middle"
					shape="default"
					onClick={() => {
						setShowSelectLanguage(true)
					}}
				>
					Add language...
				</Button>
			)}

			{showSelectLanguage && (
				<Select
					showSearch
					options={getLanguageOptions()}
					placeholder="Type a language..."
					filterOption={(input, option) =>
						(option?.label ?? "").toLowerCase().includes(input.toLowerCase()) ||
						(option?.value ?? "").toLowerCase().includes(input.toLowerCase())
					}
					onChange={addLanguage}
				/>
			)}
		</S.SelectLanguage>
	)

	return (
		<>
			<PanelContainer title="Language">
				{LanguageOptions}
				{SelectLanguage}
			</PanelContainer>

			<PanelContainer title="Voice over">
				{card.type === "multipleChoiceQuestion" && card.text && (
					<>
						<AudioFileInput
							textId={card.textId}
							audioFile={savedAudioFile}
							updateAudioFile={(val) => updateVoiceClip(val)}
							description={`Question: ${card.text}`}
							card={card}
							languageCode={languageCode}
						/>
						{(card.answerOptions || []).map((answerOption, index) => (
							<AudioFileInput
								textId={answerOption.textId}
								key={index}
								audioFile={
									answerOption.voiceClips &&
									answerOption.voiceClips.filter(
										(item) =>
											item.textId === answerOption.textId && item.languageCode === languageCode
									).length > 0
										? answerOption.voiceClips.filter(
												(item) =>
													item.textId === answerOption.textId &&
													item.languageCode === languageCode
										  )[0].fileUrl
										: undefined
								}
								updateAudioFile={(val) => updateQuestionAnswerVoiceClip(val, index)}
								description={`Answer: ${answerOption.text}`}
								card={card}
								languageCode={languageCode}
							/>
						))}
					</>
				)}
				{/* @ts-ignore */}
				{card.message && (
					<AudioFileInput
						textId={card.textId}
						audioFile={savedAudioFile}
						updateAudioFile={(val) => updateVoiceClip(val)}
						// @ts-ignore
						description={card.message}
						card={card}
						languageCode={languageCode}
					/>
				)}
				{card.type === "callOut" && (
					<AudioFileInput
						textId={card.textId}
						audioFile={savedAudioFile}
						updateAudioFile={(val) => updateVoiceClip(val)}
						description={(card.elements || []).map((item) => item.text).join(",")}
						card={card}
						languageCode={languageCode}
					/>
				)}
				{card.type === "multipleChoiceSurvey" &&
					(card.surveyOptions || []).map((surveyOption, index) => (
						<AudioFileInput
							textId={surveyOption.textId}
							key={index}
							audioFile={
								surveyOption.voiceClips &&
								surveyOption.voiceClips.filter(
									(item) => item.textId === surveyOption.textId && item.languageCode === languageCode
								).length > 0
									? surveyOption.voiceClips.filter(
											(item) =>
												item.textId === surveyOption.textId &&
												item.languageCode === languageCode
									  )[0].fileUrl
									: undefined
							}
							updateAudioFile={(val) => updateSurveyOptionVoiceClip(val, index)}
							description={`Option: ${surveyOption.text}`}
							card={card}
							languageCode={languageCode}
						/>
					))}
			</PanelContainer>
			{permissions.canView(Resource.enum.TEXT_TO_SPEECH) && <TextToSpeech cardId={card.id as string} />}
		</>
	)
}

function getLanguageOptions(): Option[] {
	return _.chain(LanguageCode.options)
		.filter((l) => !DEFAULT_LANGUAGES.map(format).includes(format(l))) // Remove all defaults and duplicates by formatted name
		.sortBy(format)
		.map((l) => ({ value: l, label: format(l) }))
		.value()
}

function getTextFromCard(card: Card): string | undefined {
	const typesWithMessage: CardType[] = [
		CardType.enum.image,
		CardType.enum.text,
		CardType.enum.multipleChoiceSurvey,
		CardType.enum.textInputSurvey,
		CardType.enum.numberInputSurvey,
		CardType.enum.button
	]

	if (typesWithMessage.includes(card.type)) {
		return (card as TextCard).message
	}

	if (card.type === CardType.enum.video) {
		return undefined
	}

	if (card.type === CardType.enum.callOut) {
		const elements = (card as CallOutCard).elements
		return elements ? elements.map((e) => e.text).join("\n") : undefined
	}

	throw new Error("Unknown card type")
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	permissions: selectPermissions
})

const mapDispatchToProps = (dispatch: any) => ({
	updateQuestion: (updateParams: MultipleChoiceQuestionCard) => dispatch(updateQuestion(updateParams)),
	updateLessonCard: (updateParams: LessonCard) => dispatch(updateLessonCard(updateParams))
})

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(VoicePanel)
