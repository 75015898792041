import React from "react"
import styled from "styled-components"

const Divider = styled.div`
	padding: 10px;
	border-bottom: solid 1px var(--light-grey);
	font-weight: bold;
`
const Container = styled.div`
	padding: 10px;
	background-color: #f0f2f4;
	border-bottom: solid 1px var(--light-grey);
`

interface Props {
	title: string
	children: any
}

const PanelContainer = ({ title, children }: Props) => {
	return (
		<>
			<Divider>{title}</Divider>
			<Container>{children}</Container>
		</>
	)
}

export default PanelContainer
