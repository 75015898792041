import React, { useEffect } from "react"
import { Redirect, Switch, Route, useRouteMatch } from "react-router-dom"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import styled from "styled-components"
import posthog from "posthog-js"

import { setOrgData, clearOrgData } from "../../redux/org/org.actions"
import { selectOrg } from "../../redux/org/org.selectors"
import { selectUserOrgKeyArray, selectHasAuthRouteAccess, selectUserInfo } from "../../redux/user/user.selectors"
import { selectPremiumFeatureAccess } from "../../redux/org/org.selectors"
import { selectSubscription } from "../../redux/subscription/subscription.selectors"
import useUrlQueryString from "../../components/hooks/useUrlQueryString"

// common page components
import SideNav from "../../components/orgApp/SideNav"
import SubscriptionWarningBand from "../../components/subscription/SubscriptionWarningBand"
import CbLoading from "../../components/common/CbLoading"
// Org App Pages
import SwitchOrg from "../settings/SwitchOrg"
import OrgSettings from "../settings/OrgSettings"
import OrgTeam from "../settings/OrgTeam"
import MyAccount from "../settings/MyAccount"
import AddTeamMembers from "../settings/AddTeamMembers"
// courses
import Courses from "../courseBuilder/Courses"
import CoursePage from "../courseBuilder/CoursePage"
import StagePage from "../courseBuilder/StagePage"
import PreviewSkeleton from "../coursePreview/PreviewSkeleton"
import CoursesAdvancedOptions from "../courseBuilder/CoursesAdvancedOptions"
import CourseTemplates from "../courseBuilder/CourseTemplates"
import CourseFolder from "../courseBuilder/CourseFolder"
// sequences
import Sequences from "../sequenceBuilder/Sequences"
// learning paths
import LearningPaths from "../learningPaths/LearningPaths"
import LearningPathBuilder from "../learningPaths/LearningPathBuilder"
// resources
import Resources from "../resources/Resources"
import AddResource from "../resources/AddResource"
// analytics
import AnalysePage from "../analytics/AnalysePage"
// management
import ManageUsers from "../management/ManageUsers"
import UserGroups from "../management/UserGroups"
import CreateUserGroup from "../../components/management/userGroups/CreateUserGroup"
import ImportUsers from "../management/ImportUsers"
import ImportUserGroups from "../management/ImportUserGroups"

import Plans from "../settings/Plans"
import Upgrade from "../settings/Upgrade"
import FeatureNoAccess from "../landing/FeatureNoAccess"
import AccountLocked from "../landing/AccountLocked"

import ArchivedUsers from "../management/ArchivedUsers"
import AccessCodes from "../management/AccessCodes"
import CreateAccessCode from "../management/CreateAccessCode"
import Subscription from "../settings/Subscription"
import SubscriptionSuccess from "../settings/SubscriptionSuccess"

// developers
import SvixCustomerPortal from "../developers/SvixCustomerPortal"
import UserSearchApi from "../userSearch/UserSearchPage"

const ContentContainer = styled.div`
	flex-direction: column;
	min-height: 100vh;
	padding-left: 0;
	box-sizing: border-box;
	display: flex;
	background: white;
	@media screen and (min-width: 850px) {
		padding-left: ${(props) => (props.fixedView ? "20px" : "230px")};
	}
`

// THESE ARE ALL POSSIBLE SUBSCRIPTION STATUSES:
// NO SUBSCRIPTION
// no_subscription

// ACTIVE
// stripe_subscription_active
// stripe_subscription_trialing
// non_stripe_subscription

// PAYMENT ISSUE
// stripe_subscription_incomplete
// stripe_subscription_past_due
// stripe_subscription_unpaid
// non_stripe_subscription_past_due

// CANCELED OR EXPIRED
// stripe_subscription_canceled
// stripe_subscription_incomplete_expired
// non_stripe_subscription_canceled
// non_stripe_subscription_expired

// IF THE USER HAS ONE OF THESE SUBSCRIPTION STATUSES THEN SHOW LOCK DOWN THE APP
const lockedSubscriptionStatuses = [
	"non_stripe_subscription_canceled",
	"non_stripe_subscription_expired",
	"stripe_subscription_canceled",
	"stripe_subscription_incomplete_expired",
	"no_subscription",
	"trial_subscription_expired"
]

const OrgApp = ({
	setOrgData,
	hasAuthRouteAccess,
	userOrgKeyArray,
	org,
	userInfo,
	match: { params },
	subscription,
	premiumFeatureAccess
}) => {
	// The `path` lets us build <Route> paths that are relative to the parent route
	let { path } = useRouteMatch()
	const [fixedView] = useUrlQueryString("fixedView")

	// set Org Auth and Org data here...
	useEffect(() => {
		// only allow org data load if user has right auth...
		if (hasAuthRouteAccess && Object.keys(userInfo.userOrgRoles).includes(params.org_id)) {
			// Note! this also dispatches getOnboardingForOrg & setOrgSubscription
			setOrgData(params.org_id)

			// set org property on events for posthog
			posthog.register({
				org_id: params.org_id
			})

			// then we'll load intercom with this specific org id
			let name =
				userInfo.info && userInfo.info.name && userInfo.info.name !== "New User" ? userInfo.info.name : null
			let email = userInfo.info && userInfo.info.email ? userInfo.info.email : null
			let created_at = userInfo.created_at ? userInfo.created_at : null
			window.Intercom("boot", {
				api_base: "https://api-iam.intercom.io",
				app_id: "vs6eapg9",
				org_id: params.org_id,
				name: name,
				email: email,
				created_at: created_at,
				user_id: userInfo.user_id,
				company: {
					company_id: params.org_id,
					name: params.org_id
				}
			})
		}
	}, [params.org_id, userInfo, setOrgData, hasAuthRouteAccess])

	// kick users out with bad auth...
	if (hasAuthRouteAccess === false || !userOrgKeyArray.includes(params.org_id)) return <Redirect to={"/"} />

	// ensure data loaded
	if (!org.orgData || !subscription) return <CbLoading fullpage={true} />

	const accountLocked =
		subscription && subscription.activePlan && lockedSubscriptionStatuses.includes(subscription.activePlan.status)

	return (
		<React.Fragment>
			{!fixedView && <SideNav />}

			<ContentContainer fixedView={fixedView}>
				<SubscriptionWarningBand />
				<Switch>
					<Route exact path={path} component={accountLocked ? AccountLocked : Courses} />
					{/* Course routes */}
					<Route exact path={`${path}/create/courses`} component={accountLocked ? AccountLocked : Courses} />
					<Route
						exact
						path={`${path}/create/courses/advanced-options`}
						component={accountLocked ? AccountLocked : CoursesAdvancedOptions}
					/>
					<Route path={`${path}/create/courses/*`} component={accountLocked ? AccountLocked : CourseFolder} />
					<Route
						exact
						path={`${path}/create/course-templates`}
						component={accountLocked ? AccountLocked : CourseTemplates}
					/>
					<Route
						exact
						path={`${path}/create/course/:course_id`}
						component={accountLocked ? AccountLocked : CoursePage}
					/>
					<Route
						exact
						path={`${path}/create/course/:course_id/stage/:stage_id`}
						component={accountLocked ? AccountLocked : StagePage}
					/>
					<Route
						exact
						path={`${path}/create/course/:course_id/stage/:stage_id/:route`}
						component={accountLocked ? AccountLocked : PreviewSkeleton}
					/>
					{/* Resource routes */}
					<Route
						exact
						path={`${path}/create/resources`}
						component={accountLocked ? AccountLocked : Resources}
					/>
					<Route
						exact
						path={`${path}/create/new-resource`}
						component={accountLocked ? AccountLocked : AddResource}
					/>
					{/* Sequences routes */}
					<Route
						exact
						path={`${path}/create/sequences`}
						render={() =>
							accountLocked ? (
								<AccountLocked />
							) : premiumFeatureAccess.sequences !== "locked" ? (
								<Sequences />
							) : (
								<FeatureNoAccess featureTitle="Sequences" />
							)
						}
					/>
					{/* Learning path routes */}
					<Route
						exact
						path={`${path}/create/learning-paths`}
						render={() =>
							accountLocked ? (
								<AccountLocked />
							) : premiumFeatureAccess.learningPaths !== "locked" ? (
								<LearningPaths />
							) : (
								<FeatureNoAccess featureTitle="Learning paths" />
							)
						}
					/>
					<Route
						exact
						path={`${path}/create/learning-path/:id`}
						component={accountLocked ? AccountLocked : LearningPathBuilder}
					/>
					{/* Analytics routes */}
					<Route exact path={`${path}/analyse`} component={accountLocked ? AccountLocked : AnalysePage} />
					<Route
						exact
						path={`${path}/analyse/:view`}
						component={accountLocked ? AccountLocked : AnalysePage}
					/>
					<Route
						exact
						path={`${path}/analyse/:view/:subview`}
						component={accountLocked ? AccountLocked : AnalysePage}
					/>
					{/* Manage routes */}
					<Route
						exact
						path={`${path}/manage/users`}
						component={accountLocked ? AccountLocked : ManageUsers}
					/>
					<Route
						exact
						path={`${path}/manage/archived-users`}
						component={accountLocked ? AccountLocked : ArchivedUsers}
					/>
					<Route
						exact
						path={`${path}/manage/import-users`}
						component={accountLocked ? AccountLocked : ImportUsers}
					/>
					<Route
						exact
						path={`${path}/manage/user-groups`}
						component={accountLocked ? AccountLocked : UserGroups}
					/>
					<Route
						exact
						path={`${path}/manage/user-groups/import`}
						component={accountLocked ? AccountLocked : ImportUserGroups}
					/>
					<Route
						exact
						path={`${path}/manage/user-groups/create`}
						component={accountLocked ? AccountLocked : CreateUserGroup}
					/>
					<Route
						exact
						path={`${path}/manage/access-codes`}
						component={accountLocked ? AccountLocked : AccessCodes}
					/>
					<Route
						exact
						path={`${path}/manage/access-codes/create`}
						component={accountLocked ? AccountLocked : CreateAccessCode}
					/>
					s{/* Settings Routes */}
					<Route exact path={`${path}/settings`} component={OrgSettings} />
					<Route exact path={`${path}/team`} component={OrgTeam} />
					<Route exact path={`${path}/switch-org`} component={SwitchOrg} />
					<Route exact path={`${path}/account`} component={MyAccount} />
					<Route exact path={`${path}/invite`} component={accountLocked ? AccountLocked : AddTeamMembers} />
					{/* Payment Routes */}
					<Route
						exact
						path={`${path}/subscription`}
						component={accountLocked ? AccountLocked : Subscription}
					/>
					<Route exact path={`${path}/subscription/success`} component={SubscriptionSuccess} />
					<Route exact path={`${path}/plans`} component={Plans} />
					<Route exact path={`${path}/upgrade`} component={Upgrade} />
					{/* Developer routes */}
					<Route
						exact
						path={`${path}/developers/webhook`}
						render={() =>
							accountLocked ? (
								<AccountLocked />
							) : premiumFeatureAccess.webhook !== "locked" ? (
								<SvixCustomerPortal />
							) : (
								<FeatureNoAccess featureTitle="Webhook" />
							)
						}
					/>
					<Route exact path={`${path}/developers/users/search`} component={UserSearchApi} />
				</Switch>
			</ContentContainer>
		</React.Fragment>
	)
}

const mapStateToProps = createStructuredSelector({
	hasAuthRouteAccess: selectHasAuthRouteAccess,
	userOrgKeyArray: selectUserOrgKeyArray,
	org: selectOrg,
	userInfo: selectUserInfo,
	subscription: selectSubscription,
	premiumFeatureAccess: selectPremiumFeatureAccess
})

const mapDispatchToProps = (dispatch) => ({
	setOrgData: (orgId) => dispatch(setOrgData(orgId)),
	clearOrgData: () => dispatch(clearOrgData())
})

export default connect(mapStateToProps, mapDispatchToProps)(OrgApp)
