import { v4 as uuidv4 } from "uuid"
import { OrgActionTypes } from "../org/org.types"
import { CoursesActionTypes } from "./courses.types"
import { setUiLoadingStart, setUiLoadingFinish } from "../ui/ui.actions"
import axios from "axios"
import { auth } from "../../firebase/firebase.util"
import { CardModel } from "../../util/model/Cards"

const updateFirebaseToken = async () => {
	const user = auth.currentUser
	// this will get the user's firebase token and fetch a new one if it has expired
	const token = await user.getIdToken()
	axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
}

// this is to set basic params from url on page in redux
export const setPageParams = (paramsObj) => (dispatch) => {
	dispatch({
		type: CoursesActionTypes.SET_PAGE_PARAMS,
		payload: paramsObj
	})
}

// need to pull course data, course objectives and org data
export const setCoursePageData = (course_id, org_id) => async (dispatch) => {
	try {
		dispatch(setUiLoadingStart())
		updateFirebaseToken()
		const orgData = await axios.get(`/api/org/${org_id}`)
		const objectivesData = await axios.get(`/api/courses/${course_id}/objectives`)
		const courseData = await axios.get(`/api/courses/${course_id}/full-with-modules-and-stages`)
		// set org data
		dispatch({
			type: OrgActionTypes.SET_ORG_DATA,
			payload: {
				...orgData.data
			}
		})
		// set page params
		dispatch({
			type: CoursesActionTypes.SET_PAGE_PARAMS,
			payload: {
				course_id,
				org_id
			}
		})
		// set objectives data
		dispatch({
			type: CoursesActionTypes.SET_OBJECTIVES_DATA,
			payload: {
				...objectivesData.data
			}
		})
		// set course data
		dispatch({
			type: CoursesActionTypes.SET_COURSE_DATA,
			payload: {
				...courseData.data
			}
		})
		dispatch(setUiLoadingFinish())
	} catch (err) {
		dispatch(setUiLoadingFinish())
		console.log(err)
	}
}

export const clearCoursePageData = () => (dispatch) => {
	dispatch({ type: CoursesActionTypes.CLEAR_COURSE_DATA })
	dispatch({ type: CoursesActionTypes.CLEAR_OBJECTIVES_DATA })
	dispatch({ type: CoursesActionTypes.CLEAR_PAGE_PARAMS_DATA })
}

export const clearStagePageData = () => (dispatch) => {
	dispatch({ type: CoursesActionTypes.CLEAR_STAGE_DATA })
	dispatch({ type: CoursesActionTypes.CLEAR_OBJECTIVES_DATA })
	dispatch({ type: CoursesActionTypes.CLEAR_PAGE_PARAMS_DATA })
}

// need to pull both stage data and course objectives
export const setStageData = (stage_id, course_id, org_id) => async (dispatch) => {
	try {
		dispatch(setUiLoadingStart())
		updateFirebaseToken()
		const objectivesData = await axios.get(`/api/courses/${course_id}/objectives`)
		const stageData = await axios.get(`/api/courses/${course_id}/stage/${stage_id}/full-with-archive`)
		dispatch({
			type: CoursesActionTypes.SET_PAGE_PARAMS,
			payload: {
				org_id,
				course_id,
				stage_id
			}
		})
		dispatch({
			type: CoursesActionTypes.SET_OBJECTIVES_DATA,
			payload: {
				...objectivesData.data
			}
		})
		dispatch({
			type: CoursesActionTypes.SET_STAGE_DATA,
			payload: {
				...stageData.data
			}
		})
		dispatch(setUiLoadingFinish())
	} catch (err) {
		dispatch(setUiLoadingFinish())
		console.log(err)
	}
}

// Helper functions for below
// -------------------------
// helper function to filter all incomplete lesson cards
const lessonCardFilter = (card_ids, cards_data) => {
	const filtered_lesson_card_ids = []
	card_ids.forEach((card_id) => {
		if (cards_data[card_id] && new CardModel(cards_data[card_id]).findErrors().length === 0) {
			filtered_lesson_card_ids.push(card_id)
		}
	})
	return filtered_lesson_card_ids
}
// helper function to filter all incomplete question cards
const questionCardFilter = (card_ids, cards_data) => {
	const filtered_question_card_ids = []
	card_ids.forEach((card_id) => {
		if (cards_data[card_id] && new CardModel(cards_data[card_id]).findErrors().length === 0) {
			filtered_question_card_ids.push(card_id)
		}
	})
	return filtered_question_card_ids
}

// update the state based on column drag, uses react-beautiful-DnD
export const updateCourseColumnsFromDrag = (result, course, moduleIdSelected) => async (dispatch) => {
	const { destination, source, draggableId } = result
	const { course_id, module_ids, module_cards } = course
	try {
		updateFirebaseToken()
		// user dropped outside droppable
		if (!destination) {
			return
		}
		// user dropped item where it was already
		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return
		}
		const start_col_id = source.droppableId
		const end_col_id = destination.droppableId

		// if user has dropped inside same column
		if (start_col_id === end_col_id) {
			// Modules column case
			// -------------------
			if (start_col_id === "modules") {
				const new_module_ids = Array.from(module_ids) // create new object, rather than mutating existing state
				new_module_ids.splice(source.index, 1) // remove item at source index
				new_module_ids.splice(destination.index, 0, draggableId) // insert item at dest index
				// update state based on res
				dispatch({
					type: CoursesActionTypes.COURSE_UPDATE_MODULES_COLUMN,
					payload: new_module_ids
				})
				// update API
				await axios.put(`/api/courses/${course_id}/module-ordering`, {
					module_ids: new_module_ids
				})
				return
			}
			// Stages column case
			// -------------------
			else if (start_col_id === "stages") {
				const new_stage_ids = Array.from(module_cards[moduleIdSelected].stage_ids) // create new object, rather than mutating existing state
				new_stage_ids.splice(source.index, 1) // remove item at source index
				new_stage_ids.splice(destination.index, 0, draggableId) // insert item at dest index
				// update state based on res
				dispatch({
					type: CoursesActionTypes.MODULE_UPDATE_STAGES_COLUMN,
					payload: {
						module_id: moduleIdSelected,
						new_stage_ids: new_stage_ids
					}
				})
				// update API
				await axios.put(`/api/courses/modules/${moduleIdSelected}/stage-ordering`, {
					stage_ids: new_stage_ids
				})
				return
			} else {
				return
			}
		}
		// if the user drops item across columns do nothing
		if (start_col_id !== end_col_id) {
			return
		}
	} catch (err) {
		console.log(err)
	}
}

// will save ordering of a stage for both lesson and questions, also in course archive
export const saveStageOrder = () => (dispatch, getState) => {
	const {
		courses: {
			pageParams: { course_id },
			stage: { stage_id, stage_columns, lesson_cards, question_cards }
		}
	} = getState()
	// use helper filter functions
	const filtered_lesson_card_ids = lessonCardFilter(stage_columns.lesson_data.card_ids, lesson_cards)
	const filtered_archive_lesson_card_ids = lessonCardFilter(stage_columns.lesson_archive.card_ids, lesson_cards)
	const filtered_question_card_ids = questionCardFilter(stage_columns.questions_data.card_ids, question_cards)
	const filtered_archive_question_card_ids = questionCardFilter(
		stage_columns.questions_archive.card_ids,
		question_cards
	)

	// save the card ordering for all 4 columns: lesson, lesson archive, questions, and questions archive
	// the filter functions will remove any references to cards with empty data
	updateFirebaseToken()
	axios
		.put(`/api/courses/${course_id}/stage/${stage_id}/card-ordering`, {
			lesson_card_ids: filtered_lesson_card_ids,
			archive_lesson_card_ids: filtered_archive_lesson_card_ids,
			question_card_ids: filtered_question_card_ids,
			archive_question_card_ids: filtered_archive_question_card_ids
		})
		.then((res) => {})
		.catch((err) => {
			console.log(err)
		})
}

// update the state based on column drag, uses react-beautiful-DnD
export const updateStageColsFromDrag = (stage_columns, result) => (dispatch) => {
	updateFirebaseToken()
	const { destination, source, draggableId } = result
	// user dropped outside droppable
	if (!destination) {
		return
	}
	// user dropped item where it was already
	if (destination.droppableId === source.droppableId && destination.index === source.index) {
		return
	}
	const start_col_id = source.droppableId
	const end_col_id = destination.droppableId
	// if user has dropped inside same column
	if (start_col_id === end_col_id) {
		const new_card_ids = Array.from(stage_columns[start_col_id].card_ids) // create new object, rather than mutating existing state
		new_card_ids.splice(source.index, 1) // remove item at source index
		new_card_ids.splice(destination.index, 0, draggableId) // insert item at dest index

		const newStartCol = {}
		newStartCol[start_col_id] = {
			card_ids: new_card_ids
		}
		dispatch({
			type: CoursesActionTypes.STAGE_UPDATE_SAME_COLUMN,
			payload: newStartCol
		})
		// update backend
		dispatch(saveStageOrder())
		return
	}
	// user dropped item in new column with new position
	if (start_col_id !== end_col_id) {
		const new_start_card_ids = Array.from(stage_columns[start_col_id].card_ids)
		new_start_card_ids.splice(source.index, 1) // remove item at source index in srart colummn

		const newStartCol = {}
		newStartCol[start_col_id] = {
			card_ids: new_start_card_ids
		}

		const new_end_card_ids = Array.from(stage_columns[end_col_id].card_ids)
		new_end_card_ids.splice(destination.index, 0, draggableId) // insert item at source index in finish column

		const newEndCol = {}
		newEndCol[end_col_id] = {
			card_ids: new_end_card_ids
		}
		dispatch({
			type: CoursesActionTypes.STAGE_UPDATE_NEW_COLUMN,
			payload: {
				newStartCol,
				newEndCol
			}
		})
		// update backend
		dispatch(saveStageOrder())
		return
	}
}

export const duplicateStageContentCard = (card, index, col_id) => async (dispatch) => {
	updateFirebaseToken()
	if (card.type === "multipleChoiceQuestion") {
		const duplicatedQuestion = new CardModel(card).noIds()
		await dispatch(createQuestion(duplicatedQuestion, index))
	}
	if (card.type !== "multipleChoiceQuestion") {
		const duplicatedLessonCard = new CardModel(card).noIds()
		await dispatch(createLessonCard(duplicatedLessonCard, index))
	}
	dispatch(saveStageOrder())
}

// create a new lesson card
export const createLessonCard = (createParams, index) => async (dispatch, getState) => {
	try {
		updateFirebaseToken()
		const {
			org: {
				orgData: { org_id }
			}
		} = getState()

		const isValid = new CardModel(createParams).findErrors().length === 0
		const result = isValid
			? await axios.post(`/api/card/${org_id}`, createParams)
			: { data: { id: `unsaved_${uuidv4()}`, ...createParams } }

		dispatch({
			type: CoursesActionTypes.CREATE_NEW_LESSON_CARD,
			payload: {
				card: result.data,
				index
			}
		})
		dispatch({
			type: CoursesActionTypes.UPDATE_CARD_EDITOR_SETTINGS,
			payload: {
				menu: "editor",
				activeCardId: result.data.id
			}
		})
		if (createParams.type === "callOut") {
			dispatch(saveStageOrder())
		}
	} catch (err) {
		console.log(err)
	}
}

// update the given lesson card content
export const updateLessonCard = (lessonCard) => async (dispatch, getState) => {
	try {
		updateFirebaseToken()
		const {
			org: {
				orgData: { org_id }
			}
		} = getState()
		const isValid = new CardModel(lessonCard).findErrors().length === 0
		let result = { data: lessonCard }
		if (isValid) {
			const isUnsaved = lessonCard.id.includes("unsaved_")
			result = isUnsaved
				? await axios.post(`/api/card/${org_id}`, { ...lessonCard, id: undefined })
				: await axios.post(`/api/card/${org_id}/${lessonCard.id}`, lessonCard)
		}
		// then update state based on response
		dispatch({
			type: CoursesActionTypes.UPDATE_LESSON_CARD_CONTENT,
			payload: {
				originalCard: lessonCard,
				updatedCard: result.data
			}
		})
		// update ordering
		dispatch(saveStageOrder())
	} catch (err) {
		console.log(err)
	}
}

// set the lesson card content without persisting
export const setLessonCardState = (lessonCard) => async (dispatch, getState) => {
	dispatch({
		type: CoursesActionTypes.UPDATE_LESSON_CARD_CONTENT,
		payload: {
			originalCard: lessonCard,
			updatedCard: lessonCard
		}
	})
}

// create a new question card
export const createQuestion = (createParams, index) => async (dispatch, getState) => {
	try {
		updateFirebaseToken()
		const {
			org: {
				orgData: { org_id }
			}
		} = getState()

		const isValid = new CardModel(createParams).findErrors().length === 0
		const result = isValid
			? await axios.post(`/api/card/${org_id}`, createParams)
			: { data: { id: `unsaved_${uuidv4()}`, ...createParams } }

		dispatch({
			type: CoursesActionTypes.CREATE_NEW_QUESTION_CARD,
			payload: {
				card: result.data,
				index
			}
		})
	} catch (err) {
		console.log(err)
	}
}

export const updateQuestion = (question) => async (dispatch, getState) => {
	try {
		updateFirebaseToken()
		const {
			org: {
				orgData: { org_id }
			}
		} = getState()

		const isValid = new CardModel(question).findErrors().length === 0
		let result = { data: question }
		if (isValid) {
			const isUnsaved = question.id.includes("unsaved_")
			result = isUnsaved
				? await axios.post(`/api/card/${org_id}`, { ...question, id: undefined })
				: await axios.post(`/api/card/${org_id}/${question.id}`, question)
		}

		dispatch({
			type: CoursesActionTypes.UPDATE_QUESTION_CARD_CONTENT,
			payload: {
				originalCard: question,
				updatedCard: result.data
			}
		})
		// update ordering
		dispatch(saveStageOrder())
	} catch (err) {
		console.log(err)
	}
}

// set the question content without persisting
export const setQuestionState = (question) => async (dispatch, getState) => {
	dispatch({
		type: CoursesActionTypes.UPDATE_QUESTION_CARD_CONTENT,
		payload: {
			originalCard: question,
			updatedCard: question
		}
	})
}

// delete given stage card from correct card_ids array on backend, then update given card_ids array and cards object on state
export const deleteStageContentCard = (card_id, card_type, index, col_id) => async (dispatch) => {
	try {
		updateFirebaseToken()
		dispatch({
			type: CoursesActionTypes.DELETE_STAGE_CONTENT_CARD,
			payload: {
				card_id,
				card_type,
				col_id,
				index
			}
		})
		// save updated order to backend
		dispatch(saveStageOrder())
	} catch (err) {
		console.log(err)
	}
}

// edit current objective
export const updateObjective = (objective_id, new_text) => async (dispatch) => {
	try {
		updateFirebaseToken()
		const res = await axios.put(`/api/courses/objectives/${objective_id}`, {
			new_content: {
				text: new_text
			}
		})
		dispatch({
			type: CoursesActionTypes.UPDATE_OBJECTIVE,
			payload: {
				...res.data
			}
		})
	} catch (err) {
		console.log(err)
	}
}

// create new objective
// NOTE: we will usually create objectives in the context of stages which is why we use the stage_id arg
export const createObjective =
	(course_id, stage_id = null, new_text) =>
	async (dispatch) => {
		try {
			updateFirebaseToken()
			const res = await axios.post(`/api/courses/${course_id}/objectives`, {
				new_content: {
					text: new_text
				},
				stage_id: stage_id // null case handled on backend
			})
			dispatch({
				type: CoursesActionTypes.CREATE_OBJECTIVE,
				payload: {
					...res.data
				}
			})
			return res.data.objective_id
		} catch (err) {
			console.log(err)
		}
	}

// switch objective
export const switchObjective = (stage_id, new_objective_id, source) => async (dispatch) => {
	try {
		updateFirebaseToken()
		const res = await axios.put(`/api/courses/stage/${stage_id}/objective`, {
			new_objective_id: new_objective_id
		})
		// update stage or course redux state based on source of action
		if (source === "stagePage") {
			dispatch({
				type: CoursesActionTypes.SWITCH_STAGE_OBJECTIVE,
				payload: {
					...res.data
				}
			})
		} else {
			// TODO: trigger some action on redux state here which forces stage card to re-render, issue
			dispatch({
				type: CoursesActionTypes.SWITCH_STAGE_CARD_OBJECTIVE,
				payload: {
					...res.data
				}
			})
		}
	} catch (err) {
		console.log(err)
	}
}

// update course info
export const updateCourseInfo = (course_id, new_info, org_id) => async (dispatch) => {
	try {
		updateFirebaseToken()
		const res = await axios.put(`/api/courses/${course_id}/info`, {
			new_info: { ...new_info },
			org_id: org_id
		})
		dispatch({
			type: CoursesActionTypes.UPDATE_COURSE_INFO,
			payload: {
				...res.data
			}
		})
		// Also update orgData to ensure that new data is reflected on
		// main courses screen...
		const orgData = await axios.get(`/api/org/${org_id}`)
		dispatch({
			type: OrgActionTypes.SET_ORG_DATA,
			payload: {
				...orgData.data
			}
		})
	} catch (err) {
		console.log(err)
	}
}

// update course parent_id
export const updateCourseParentId = (course_id, course_folder_parent_id, org_id) => async (dispatch) => {
	try {
		updateFirebaseToken()
		await axios.put(`/api/courses/${course_id}/parent`, {
			course_folder_parent_id,
			org_id
		})
		// Update orgData to ensure that new data is reflected on
		// main courses screen...
		const orgData = await axios.get(`/api/org/${org_id}`)
		dispatch({
			type: OrgActionTypes.SET_ORG_DATA,
			payload: {
				...orgData.data
			}
		})
	} catch (err) {
		console.log(err)
	}
}

// create a course folder
export const createCourseFolder = (org_id, folder_data) => async (dispatch) => {
	try {
		updateFirebaseToken()
		await axios.post(`/api/courses/create-new-folder/${org_id}`, {
			folder_data
		})
		// Update orgData to ensure that new data is reflected on
		// main courses screen...
		const orgData = await axios.get(`/api/org/${org_id}`)
		dispatch({
			type: OrgActionTypes.SET_ORG_DATA,
			payload: {
				...orgData.data
			}
		})
	} catch (err) {
		console.log(err)
	}
}

// update a course folder
export const updateCourseFolder = (org_id, folder_id, folder_data) => async (dispatch) => {
	try {
		updateFirebaseToken()
		await axios.put(`/api/courses/folder/${folder_id}/info`, {
			folder_data,
			org_id
		})
		// Update orgData to ensure that new data is reflected on
		// main courses screen...
		const orgData = await axios.get(`/api/org/${org_id}`)
		dispatch({
			type: OrgActionTypes.SET_ORG_DATA,
			payload: {
				...orgData.data
			}
		})
	} catch (err) {
		console.log(err)
	}
}

// update a course folder parent
export const updateCourseFolderParentId = (folder_id, course_folder_parent_id, org_id) => async (dispatch) => {
	try {
		updateFirebaseToken()
		await axios.put(`/api/courses/folder/${folder_id}/parent`, {
			course_folder_parent_id,
			org_id
		})
		// Update orgData to ensure that new data is reflected on
		// main courses screen...
		const orgData = await axios.get(`/api/org/${org_id}`)
		dispatch({
			type: OrgActionTypes.SET_ORG_DATA,
			payload: {
				...orgData.data
			}
		})
	} catch (err) {
		console.log(err)
	}
}

export const deleteCourseFolder = (folder_id, org_id) => async (dispatch) => {
	try {
		updateFirebaseToken()
		await axios.delete(`/api/courses/folder/${org_id}/${folder_id}`)
		// Update orgData to ensure that new data is reflected on
		// main courses screen...
		const orgData = await axios.get(`/api/org/${org_id}`)
		dispatch({
			type: OrgActionTypes.SET_ORG_DATA,
			payload: {
				...orgData.data
			}
		})
	} catch (err) {
		console.log(err)
	}
}

// create new module
export const createNewModule = (course_id, new_module_name) => async (dispatch) => {
	const image_arr = [
		"https://farminkweb.s3.amazonaws.com/a996e7f8-e6f2-4b3d-988c-7b63a51c877d.png",
		"https://farminkweb.s3.eu-west-2.amazonaws.com/095c64ff-1315-4578-be32-51da93b03bdc.png",
		"https://farminkweb.s3.eu-west-2.amazonaws.com/84e4f784-4e23-44fe-85ab-fd1204c278a7.png",
		"https://farminkweb.s3.eu-west-2.amazonaws.com/9f1dfc96-4f34-4430-b017-b1be4d0f9c3f.png"
	]

	try {
		updateFirebaseToken()
		const res = await axios.post(`/api/courses/${course_id}/modules`, {
			new_info: {
				name: new_module_name,
				image: image_arr[~~(Math.random() * image_arr.length)]
			}
		})
		dispatch({
			type: CoursesActionTypes.CREATE_NEW_MODULE_CARD,
			payload: {
				...res.data
			}
		})
	} catch (err) {
		console.log(err)
	}
}

// delete module
export const deleteModuleInCourse = (course_id, module_id) => async (dispatch) => {
	try {
		updateFirebaseToken()
		const res = await axios.delete(`/api/courses/${course_id}/module/${module_id}`)
		dispatch({
			type: CoursesActionTypes.DELETE_MODULE_CARD,
			payload: {
				...res.data
			}
		})
	} catch (err) {
		console.log(err)
	}
}

// update module info
export const updateModuleInfo = (module_id, new_info) => async (dispatch) => {
	try {
		updateFirebaseToken()
		const res = await axios.put(`/api/courses/modules/${module_id}/info`, {
			new_info: new_info
		})
		dispatch({
			type: CoursesActionTypes.UPDATE_MODULE_CARD,
			payload: {
				...res.data
			}
		})
	} catch (err) {
		console.log(err)
	}
}

/**
 * @description - create new stage
 * @param course_id
 * @param module_id
 * @param new_stage_name
 * @param objective_payload - { new_text: string } | { objective_id: string }
 * @param stageSourceFile - pdf/text file
 * @param prompts
 * @param model
 * @param orgId - string
 * @returns {(function(*): Promise<void>)|*}
 */
export const createNewStage =
	(course_id, module_id, new_stage_name, objective_payload, stageSourceFile, prompts, model, orgId) => async (dispatch) => {
		try {
			await updateFirebaseToken()
			let objective_id
			const isNewObjective = !!objective_payload.new_text

			// CASE 1: Need to create new objective
			if (isNewObjective) {
				const createNewObjective = await dispatch(createObjective(course_id, null, objective_payload.new_text))
				objective_id = createNewObjective
			}
			// CASE 2: select existing objective
			else {
				objective_id = objective_payload.objective_id
			}

			// make sure we've handled the objective id
			if (!objective_id) {
				throw new Error("No objective_id found while creating new stage")
			}

			const formData = new FormData()
			formData.append("course_id", course_id)
			formData.append("new_stage_name", new_stage_name)
			formData.append("objective_id", objective_id)
			formData.append("prompts", prompts)
			formData.append("model", model)
			formData.append("orgId", orgId)

			if (stageSourceFile && stageSourceFile.name) {
				formData.append("stageSourceFile", stageSourceFile, stageSourceFile.name)
			}

			const config = { headers: { "Content-Type": "multipart/form-data" } }

			const res = await axios.post(`/api/courses/modules/${module_id}/create-stage`, formData, config)
			// create a new stage card
			dispatch({
				type: CoursesActionTypes.CREATE_NEW_STAGE_CARD_IN_MODULE,
				payload: {
					...res.data,
					module_id: module_id // need to add module_id for our state calc
				}
			})
		} catch (err) {
			console.log(err)
		}
	}

// move stage to a module
export const moveStageToNewModule = (stage_id, old_module_id, new_module_id) => async (dispatch) => {
	// only need to handle new module id case
	if (old_module_id !== new_module_id) {
		try {
			updateFirebaseToken()
			// on backend... first remove stage from old module
			const delRes = await axios.delete(`/api/courses/${old_module_id}/stage/${stage_id}`)
			// then add stage card to new module
			const putRes = await axios.put(`/api/courses/${new_module_id}/stage/${stage_id}`)
			dispatch({
				type: CoursesActionTypes.MOVE_STAGE_CARD_TO_NEW_MODULE,
				payload: {
					old_module_id: delRes.data.module_id,
					new_module_id: putRes.data.module_id,
					stage_id: stage_id
				}
			})
		} catch (err) {
			console.log(err)
		}
	} else {
		// do nothing
	}
}

// delete module
export const deleteStageInModule = (stage_id, module_id) => async (dispatch) => {
	try {
		updateFirebaseToken()
		const res = await axios.delete(`/api/courses/${module_id}/stage/${stage_id}`)
		dispatch({
			type: CoursesActionTypes.DELETE_STAGE_CARD,
			payload: {
				...res.data
			}
		})
	} catch (err) {
		console.log(err)
	}
}

// update module info
export const updateStageInfo = (stage_id, new_info) => async (dispatch) => {
	try {
		updateFirebaseToken()
		const res = await axios.put(`/api/courses/stage/${stage_id}/info`, {
			new_info: new_info
		})
		dispatch({
			type: CoursesActionTypes.UPDATE_STAGE_CARD,
			payload: {
				...res.data
			}
		})
	} catch (err) {
		console.log(err)
	}
}

// course preview action
export const createCoursePreview = (org_id, course_id, publish_options) => async (dispatch) => {
	try {
		updateFirebaseToken()
		const res = await axios.post(`/api/preview/org/${org_id}/course/${course_id}/create-preview`, {
			publish_options
		})
		if (res && res.data && res.data.info && res.data.info.preview_url) {
			dispatch({
				type: CoursesActionTypes.UPDATE_COURSE_INFO,
				payload: res.data
			})
			const orgData = await axios.get(`/api/org/${org_id}`)
			dispatch({
				type: OrgActionTypes.SET_ORG_DATA,
				payload: {
					...orgData.data
				}
			})
			const response = {
				status: "ok",
				preview_url: res.data.info.preview_url
			}
			return response
		} else {
			const err = {
				status: "failed"
			}
			throw err
		}
	} catch (err) {
		throw err
	}
}

// course publish action
export const publishCourse = (org_id, course_id, publish_options, user_groups) => async (dispatch) => {
	try {
		updateFirebaseToken()
		const res = await axios.post(`/api/preview/org/${org_id}/course/${course_id}/publish-course`, {
			publish_options,
			user_groups
		})
		if (res && res.data && res.data.info && res.data.info.publish_url) {
			dispatch({
				type: CoursesActionTypes.UPDATE_COURSE_INFO,
				payload: res.data
			})
			const orgData = await axios.get(`/api/org/${org_id}`)
			dispatch({
				type: OrgActionTypes.SET_ORG_DATA,
				payload: {
					...orgData.data
				}
			})
			const response = {
				status: "ok",
				publish_url: res.data.info.publish_url
			}
			return response
		} else {
			const err = {
				status: "failed"
			}
			throw err
		}
	} catch (err) {
		console.log(err)
	}
}

// duplciate module to a new course
export const duplicateModuleToNewCourse =
	(module_id, current_course_id, target_course_id, org_id) => async (dispatch) => {
		try {
			updateFirebaseToken()
			await axios.post(`/api/courses/modules/${module_id}/duplicate-to/${target_course_id}`)
			// if you duplicate to same course then we need to update the redux data
			// for the current course view...
			if (current_course_id === target_course_id) {
				dispatch(setCoursePageData(current_course_id, org_id))
			}
		} catch (err) {
			console.log(err)
		}
	}

// create new course from a template, course_id must be in format "template_"
export const createNewCourseFromTemplate =
	(org_id, course_info, template_course_id, course_folder_parent_id) => async (dispatch) => {
		try {
			updateFirebaseToken()
			const res = await axios.post(
				`/api/courses/create-new-course-from-template/${template_course_id}/to-org/${org_id}`,
				{
					new_course_info: course_info,
					course_folder_parent_id,
					copyToRootFolder: true
				}
			)
			if (res && res.data && res.data.new_course_id && res.data.new_course_info) {
				// need to update the org reducer, not course reducer for course cards
				await dispatch({
					type: OrgActionTypes.CREATE_NEW_COURSE,
					payload: {
						course_id: res.data.new_course_id,
						info: res.data.new_course_info,
						course_folder_parent_id
					}
				})
			} else {
				throw new Error("No course_info found when creating course from template")
			}
		} catch (err) {
			throw err
		}
	}

// duplicate a course with id for a given org
export const duplicateCourseWithId = (org_id, course_id, new_course_title) => async (dispatch) => {
	try {
		updateFirebaseToken()
		const res = await axios.post(`/api/courses/duplicate-course/${course_id}/to-org/${org_id}`, {
			new_course_title
		})
		/*
        expect response of form...
        {
          org_id: {org_id} ,
          new_course_id: {new_course_id} ,
          new_course_info: {new_course_info} ,
          new_org_course_ids: {new_org_course_ids}
        }
    */
		if (res && res.data && res.data.new_course_id && res.data.new_course_info) {
			// need to update the org reducer, not course reducer for course cards
			await dispatch({
				type: OrgActionTypes.CREATE_NEW_COURSE,
				payload: {
					course_id: res.data.new_course_id,
					info: res.data.new_course_info,
					course_folder_parent_id: res.data.course_folder_parent_id
				}
			})
		} else {
			console.log("ERROR - got unexpected data from res: ", res)
		}
	} catch (err) {
		console.log(err)
	}
}

// archive course here...
// this involves a publish operation that will change view and access user groups to 'archive'
export const archiveCourseWithId = (org_id, course_id) => async (dispatch) => {
	try {
		updateFirebaseToken()
		const res = await axios.put(`/api/courses/archive/${course_id}/for-org/${org_id}`)

		if (res.data && res.data.info) {
			// need to update the org reducer, not course reducer for course cards
			await dispatch({
				type: OrgActionTypes.UPDATE_COURSE_CARD_IN_ORG,
				payload: {
					course_id: res.data.course_id,
					info: res.data.info
				}
			})
		} else {
			console.log("ERROR - got unexpected data from res.data: ", res.data)
		}
	} catch (err) {
		console.log(err)
	}
}

// archive course here...
// this involves a publish operation that will change view and access user groups to 'archive'
export const unarchiveCourseWithId = (org_id, course_id) => async (dispatch) => {
	try {
		updateFirebaseToken()
		const res = await axios.put(`/api/courses/unarchive/${course_id}/for-org/${org_id}`)
		if (res.data && res.data.info) {
			// need to update the org reducer, not course reducer for course cards
			await dispatch({
				type: OrgActionTypes.UPDATE_COURSE_CARD_IN_ORG,
				payload: {
					course_id: res.data.course_id,
					info: res.data.info
				}
			})
		} else {
			console.log("ERROR - got unexpected data from res.data: ", res.data)
		}
	} catch (err) {
		console.log(err)
	}
}

// delete course here...
export const deleteCourseWithId = (org_id, course_id) => async (dispatch) => {
	try {
		updateFirebaseToken()
		const res = await axios.delete(`/api/courses/${course_id}/for-org/${org_id}`)

		if (res.data && res.data.course_id) {
			// need to update the org reducer, not course reducer for course cards
			await dispatch({
				type: OrgActionTypes.DELETE_COURSE_CARD_FOR_ORG,
				payload: {
					course_id: res.data.course_id
				}
			})
		} else {
			console.log("ERROR - got unexpected data from res.data: ", res.data)
		}
	} catch (err) {
		console.log(err)
	}
}

// download JSON of course object
export const downloadCourseWithId = (org_id, course_id) => async (dispatch) => {
	updateFirebaseToken()
	axios({
		url: `/api/courses/download/${course_id}/from-org/${org_id}`,
		method: "GET",
		responseType: "blob" // important
	})
		.then((res) => {
			const url = window.URL.createObjectURL(new Blob([res.data]))
			const link = document.createElement("a")
			link.href = url
			link.setAttribute("download", "course_download.json")
			document.body.appendChild(link)
			link.click()
		})
		.catch((err) => {
			console.log(err)
		})
}

export const translateCourseWithId =
	(org_id, course_id, selected_language = null) =>
	async (dispatch) => {
		try {
			updateFirebaseToken()
			const res = await axios.post(`/api/courses/translate-course/${course_id}/from-org/${org_id}`, {
				selected_language
			})
			if (res && res.data && res.data.new_course_id && res.data.new_course_info) {
				// need to update the org reducer, not course reducer for course cards
				await dispatch({
					type: OrgActionTypes.CREATE_NEW_COURSE,
					payload: {
						course_id: res.data.new_course_id,
						info: res.data.new_course_info
					}
				})
			} else {
				console.log("ERROR - got unexpected data from res: ", res)
			}
		} catch (err) {
			console.log(err)
		}
	}

// set editor menu
export const updateCardEditorSettings = (editor) => async (dispatch) => {
	dispatch({
		type: CoursesActionTypes.UPDATE_CARD_EDITOR_SETTINGS,
		payload: editor
	})
}
