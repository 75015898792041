import { Modal } from "antd"
import React, { useEffect, useRef, useState } from "react"
import { AudioFilled, CaretRightFilled, CheckOutlined, RedoOutlined, SaveFilled } from "@ant-design/icons"
import AudioRecorder from "./AudioRecorder"
import WaveSurfer from "wavesurfer.js"
import * as S from "./RecordAudioModal.styles"
import { DEFAULT_WAVEFORM_OPTIONS } from "./RecordAudioModal.styles"
import { secondsToHHmm } from "learnink-common/lib/tools/tools"
import Routes from "../../../util/Routes"
import { useApi } from "../../../util/useApi"
import { createStructuredSelector } from "reselect"
import { selectOrgId } from "../../../redux/org/org.selectors"
import { selectCurrentStageId } from "../../../redux/courses/courses.selectors"
import { connect } from "react-redux"
import { selectUserInfo } from "../../../redux/user/user.selectors"
import { setQuestionState, setLessonCardState } from "../../../redux/courses/courses.actions"
import { VoiceClip } from "learnink-common/lib/domains/voiceClips/VoiceClip.model"
import { LanguageCode } from "learnink-common/lib/constants/language/LanguageCode"
import { TeacherOrLearner } from "learnink-common/lib/domains/cards/lessons/BaseLessonCard.model"
import { Card } from "learnink-common/lib/domains/cards/Card.model"
import { LessonCard } from "learnink-common/lib/domains/cards/lessons/LessonCard.model"
import { QuestionCard } from "learnink-common/lib/domains/cards/questions/QuestionCard.model"

type ModalState = "start" | "countdown" | "record" | "review"

interface RecordAudioModalProps {
	textId: string
	userInfo: { user_id: string }
	orgId: string
	stageId: string
	card: Card
	languageCode: LanguageCode
	textToRecord: string | undefined
	modalIsOpen: boolean
	setModalIsOpen: (modalIsOpen: boolean) => void
	setLessonCard: (updateParams: LessonCard) => void
	setQuestion: (updateParams: QuestionCard) => void
}

interface RecordAudioState {
	modalIsOpen: boolean
	setModalIsOpen: (modalIsOpen: boolean) => void
}

interface ProcessAudioParams {
	userId: string
	orgId: string
	stageId: string
	cardId: string
	languageCode: LanguageCode
	source: Blob
}

export const useRecordAudioProps = (): RecordAudioState => {
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)

	return { modalIsOpen, setModalIsOpen }
}

const RecordAudioModal = ({
	textId,
	userInfo,
	orgId,
	stageId,
	card,
	languageCode,
	textToRecord,
	modalIsOpen,
	setModalIsOpen,
	setLessonCard,
	setQuestion
}: RecordAudioModalProps) => {
	const SECOND_IN_MILLIS = 1000
	const [modalState, setModalState] = useState<ModalState>("start")
	const [blob, setBlob] = useState<Blob | undefined>(undefined)
	const [waveform, setWaveform] = useState<WaveSurfer | undefined>(undefined)

	const waveformRef = useRef<HTMLDivElement>(null)
	const audioRecorder = new AudioRecorder()
	const { setApiRequest: processAudioFile } = useApi<ProcessAudioParams, VoiceClip>()

	const closeModal = () => {
		void handle.reset()
		setModalIsOpen(false)
	}

	//	 TODO - fix once we have full card types
	const refreshCard = card.type !== "multipleChoiceQuestion" ? setLessonCard : setQuestion

	const updateCardVoiceClip = (voiceClip: VoiceClip): Card => {
		const newCard = { ...card }
		//	Initialise card
		newCard.voiceClips = [...(newCard?.voiceClips || [])]

		//	Get the current index of the voice clip if it exists
		const voiceClipIndex = newCard?.voiceClips
			.map(({ id }, index) => ({ id, index }))
			.find((item) => item.id === voiceClip.id)?.index

		//	Update or add to voice clips
		if (voiceClipIndex) {
			newCard.voiceClips[voiceClipIndex] = voiceClip
		} else {
			newCard.voiceClips.push(voiceClip)
		}

		return newCard
	}

	const handleProcessAudio = () => {
		const formData = getFormData()
		processAudioFile({
			route: Routes.processAudioFile,
			urlParams: { stageId },
			formData,
			onSuccess: (voiceClip) => {
				refreshCard(updateCardVoiceClip(voiceClip) as unknown as never)
				closeModal()
			},
			onError: console.log
		})
	}

	const getFormData = (): FormData => {
		if (!blob) throw new Error("source needs to be created first...")

		const formData = new FormData()
		formData.set("userId", userInfo?.user_id)
		formData.set("orgId", orgId)
		formData.set("stageId", stageId)
		formData.set("textId", textId)
		formData.set("languageCode", languageCode)
		formData.set("source", blob, "defaultFilename." + blob.type)

		return formData
	}

	const renderWaveform = () => {
		if (waveformRef.current && blob && !waveform) {
			const waveSurfer = WaveSurfer.create({ container: waveformRef.current, ...DEFAULT_WAVEFORM_OPTIONS })
			void waveSurfer.loadBlob(blob)
			setWaveform(waveSurfer)
		}
	}
	useEffect(renderWaveform, [modalIsOpen, waveformRef, blob, waveform])

	const recording = {
		start: () => audioRecorder.start().catch(console.log),
		stop: () => audioRecorder.stop().then(setBlob).catch(console.log),
		cancel: () => {
			setBlob(undefined)
			setWaveform(undefined)
			audioRecorder.cancel()
		}
	}

	const handle = {
		start: () => setModalState("countdown"),
		stop: () => {
			void recording.stop()
			setModalState("review")
		},
		reset: () => {
			recording.cancel()
			setModalState("start")
			if (waveform) {
				waveform.empty()
				waveform.destroy()
			}
		},
		play: async () => void waveform?.play(),
		save: handleProcessAudio
	}

	const Countdown = () => {
		const STARTING_COUNT = 3
		const [count, setCount] = useState(STARTING_COUNT)

		const countdown = () => {
			const timeout = setTimeout(() => {
				if (count > 1) setCount(count - 1)
				else setModalState("record")
			}, SECOND_IN_MILLIS)

			return () => clearTimeout(timeout)
		}

		useEffect(countdown, [count])

		return <S.Countdown>{count}</S.Countdown>
	}

	const Recording = () => {
		const MAX_RECORD_TIME_SECONDS = 60
		const [recordTimeSeconds, setRecordTimeSeconds] = useState(0)

		useEffect(() => void recording.start(), [])

		const incrementTime = () => {
			const timeout = setTimeout(() => {
				if (recordTimeSeconds < MAX_RECORD_TIME_SECONDS) {
					setRecordTimeSeconds(recordTimeSeconds + 1)
				} else {
					handle.stop()
				}
			}, SECOND_IN_MILLIS)

			return () => clearTimeout(timeout)
		}
		useEffect(incrementTime, [recordTimeSeconds])

		const props = {
			redDotIsHidden: recordTimeSeconds % 2 === 1,
			timeFormatted: secondsToHHmm(recordTimeSeconds)
		}

		return <S.Recording {...props} />
	}

	if (!textId) {
		return (
			<Modal open={modalIsOpen} title="Record an audio clip" onCancel={closeModal} footer={null}>
				Your cards need to be updated to use the record audio feature, please contact support
			</Modal>
		)
	}

	const Button = {
		Redo: () => {
			return <S.HugeButton title="Restart" icon={<RedoOutlined style={S.largeIcon} />} onClick={handle.reset} />
		},
		StartRecord: () => {
			return <S.HugeButton title="Start" icon={<AudioFilled style={S.largeIcon} />} onClick={handle.start} />
		},
		StopRecord: () => {
			return <S.HugeButton title="Finish" icon={<CheckOutlined style={S.largeIcon} />} onClick={handle.stop} />
		},
		Replay: () => {
			return (
				<S.HugeButton title="Playback" icon={<CaretRightFilled style={S.largeIcon} />} onClick={handle.play} />
			)
		},
		Save: () => {
			return <S.HugeButton title="Save" icon={<SaveFilled style={S.largeIcon} />} onClick={handle.save} />
		}
	}

	const is = (inState: ModalState) => modalState === inState

	const teacherOrLearner = (card as LessonCard)?.from || ("teacher" as TeacherOrLearner)

	return (
		<Modal open={modalIsOpen} title="Record an audio clip" onCancel={closeModal} footer={null}>
			{is("countdown") && <Countdown />}
			{is("record") && <Recording />}

			<div ref={waveformRef} />

			<S.CueCard {...{ textToRecord, teacherOrLearner }} />

			<S.OptionsFooter>
				{is("start") && <Button.StartRecord />}
				{is("countdown") && <Button.Redo />}
				{is("record") && (
					<>
						<Button.Redo />
						<Button.StopRecord />
					</>
				)}

				{is("review") && (
					<>
						<Button.Replay />
						<Button.Redo />
						<Button.Save />
					</>
				)}
			</S.OptionsFooter>
		</Modal>
	)
}

const mapStateToProps = createStructuredSelector({
	userInfo: selectUserInfo,
	orgId: selectOrgId,
	stageId: selectCurrentStageId
})

const mapDispatchToProps = (dispatch: any) => ({
	setQuestion: (card: QuestionCard) => dispatch(setQuestionState(card)),
	setLessonCard: (card: LessonCard) => dispatch(setLessonCardState(card))
})

export default connect(mapStateToProps, mapDispatchToProps)(RecordAudioModal)
