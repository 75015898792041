import React, { useState, useEffect } from "react"
import axios from "axios"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"

import { selectOrgId, selectOrgUserGroups } from "../../redux/org/org.selectors"
import { userTableColumns } from "../../components/management/userTable/UserTable.model"
import { User } from "learnink-common/lib/domains/userManagement/users/User.model"
import * as S from "../../components/styles/PageStyles"

import LpBackButton from "../../components/common/LpBackButton"
import DashboardHeaderButton from "../../components/elements/DashboardHeaderButton"
import UserTableQuery from "../../components/management/userTable/UserTableQuery"
import TableFilter from "../../components/management/userTable/TableFilter"
import CbLoading from "../../components/common/CbLoading"
import GeNoItemsFound from "../../components/common/GeNoItemsFound"

const ArchivedUsers = ({ orgId, orgUserGroups }) => {
	const [filters, setFilters] = useState([])
	const [queryData, setQueryData] = useState(null)
	const [pageState, setPageState] = useState("loading")

	const getArchivedUserList = async () => {
		try {
			const { data } = await axios.post(`/api/userManagement/${orgId}/users/search`, {
				limit: 50000,
				searchFilters: [{ member: "registration_status", operator: "equals", values: ["archived"] }]
			})
			data.forEach((row) => {
				row.userGroupNames = row.userGroups.map((item) =>
					orgUserGroups[item] ? orgUserGroups[item].user_group_info.name : null
				)
			})
			setQueryData(data)
			setPageState("default")
		} catch (err) {
			console.log(err)
			setPageState("error")
		}
	}

	const removeUsersFromList = (userIds) => {
		const userList = queryData.filter((item) => !userIds.includes(item.userId))
		setQueryData(userList)
	}

	useEffect(() => {
		getArchivedUserList()
	}, [])

	const removeFilter = (filter) => {
		const newFilters = filters.filter(
			(item) =>
				item.member + item.operator + item.values.toString() !==
				filter.member + filter.operator + filter.values.toString()
		)
		setFilters(newFilters)
	}

	const setNewFilter = (activeFilter) => {
		const newFilter = [
			{
				member: activeFilter.member,
				operator: activeFilter.operator,
				values: activeFilter.values
			}
		]
		const newFilters = [...filters, ...newFilter]
		setFilters(newFilters)
	}

	return (
		<S.Container>
			<S.BackContainer>
				<LpBackButton
					backlink={`/org/${orgId}/manage/users?filters=%5B%7B"member"%3A"registrationStatus"%2C"operator"%3A"includes"%2C"values"%3A%5B"registered"%5D%7D%5D`}
				/>
			</S.BackContainer>
			<DashboardHeaderButton title={"Archived users"} smWidth="96%" laWidth="96%" />
			<TableFilter
				filters={filters}
				removeFilter={removeFilter}
				setNewFilter={setNewFilter}
				filterMembers={["phoneNumber", "userId", "customId"]}
			/>
			{pageState === "default" && (
				<UserTableQuery
					registeredOn={false}
					unregisteredOn={false}
					archivedOn
					filters={filters}
					orgId={orgId}
					userList={queryData}
					removeUsersFromList={removeUsersFromList}
					actions={["unarchive"]}
					columns={userTableColumns.filter((item) =>
						[...Object.keys(User.shape), "userGroupNames"].includes(item.id)
					)}
					hiddenColumns={["firstName", "lastName", "lastActive", "registeredAt"]}
				/>
			)}
			{pageState === "loading" && <CbLoading />}
			{pageState === "error" && (
				<GeNoItemsFound
					titleText="Slow query"
					subtitleText="It looks like this query failed to load in time. Please check your connection"
					icon={<FontAwesomeIcon icon={faExclamationCircle} />}
				/>
			)}
		</S.Container>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	orgUserGroups: selectOrgUserGroups
})

export default connect(mapStateToProps)(ArchivedUsers)
