// @ts-ignore
import numeral from "numeral"
import { LanguageCode } from "learnink-common/lib/constants/language/LanguageCode"
import { LanguageByLanguageCode } from "learnink-common/lib/constants/language/LanguageByLanguageCode"

interface HoursAndMinutes {
	hours: number
	minutes: number
}

export function toHoursAndMinutes(totalMinutes: number) {
	const hours = Math.floor(totalMinutes / 60)
	const minutes = totalMinutes % 60

	return { hours, minutes } as HoursAndMinutes
}

export function toHoursAndMinutesShortText(totalMinutes: number) {
	const totalTrainingTime = toHoursAndMinutes(totalMinutes)
	return `~ ${totalTrainingTime.hours} hr ${totalTrainingTime.minutes} mins`
}

export function tickFormatter(number: number) {
	return numeral(number).format("0,0")
}

export function generateRandomElementFromArray(array: any) {
	return array[Math.floor(Math.random() * array.length)]
}

export const calculateAverage = (tableData: any[], field: string, numDecimalPlaces: number) => {
	const values = tableData.filter((item) => item[field] && !isNaN(item[field])).map((item) => item[field])
	if (values.length === 0) {
		return "--"
	}
	// @ts-ignore
	const average = values.reduce((a, b) => a + b, 0) / values.length
	if (numDecimalPlaces === 0) {
		return Math.round(average)
	}
	return Math.round(average * Math.pow(10, numDecimalPlaces)) / Math.pow(10, numDecimalPlaces)
}

export const toFormattedLanguage = (languageCode: LanguageCode): string => {
	const language = LanguageByLanguageCode[languageCode]?.language || "Unknown"
	const separator = ";"
	return language.includes(separator) ? language.split(separator)[0] : language
}
