import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { useParams } from "react-router-dom"
import axios from "axios"
import _ from "lodash"
// @ts-ignore
import { DragDropContext } from "react-beautiful-dnd"

import { selectLearningPaths, selectOrgId, selectRoleTypeUserGroups } from "../../redux/org/org.selectors"
import { publishLearningPath, updateFirebaseToken } from "../../redux/org/org.actions"
import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import * as S from "../../components/styles/PageStyles"
import { UserGroup } from "learnink-common/lib/domains/userGroups/UserGroup.model"

import { UUID } from "learnink-common/lib/types/types"
import CbLoading from "../../components/common/CbLoading"
import { LearningPathContent, UpdatedSequences } from "./utils/LearningPathContent"
import { Sequence } from "learnink-common/lib/domains/sequences/Sequence.model"
import Nav from "../../components/learningPathBuilder/Nav"
import GuideColumn from "../../components/learningPathBuilder/guide/GuideColumn"
import ContentColumn from "../../components/learningPathBuilder/content/ContentColumn"
import CreateEditSequence from "../../components/learningPathBuilder/modals/CreateEditSequence"
import PublishLearningPath from "../../components/learningPathBuilder/modals/PublishLearningPath"
import EditorColumn from "../../components/learningPathBuilder/editor/EditorColumn"
import { SequenceContent } from "./utils/SequenceContent"
import EditLearningPathOnCompletion from "../../components/learningPathBuilder/modals/EditLearningPathOnCompletion"
import ShareLearningPath from "../../components/learningPathBuilder/modals/ShareLearningPath"
import { ModalState } from "../../util/model/PageStates"
import { Alert, Modal } from "antd"
import EditSequenceTimeline from "../../components/learningPathBuilder/modals/EditSequenceTimeline"
import EditUnlockTrigger from "../../components/learningPathBuilder/modals/EditUnlockTrigger"
import useUrlQueryString from "../../components/hooks/useUrlQueryString"

export type EditorColumnState = "timeline" | "settings" | "courseLibrary"

export type ModalTypes =
	| "none"
	| "save"
	| "addSequence"
	| "editSequence"
	| "deleteSequence"
	| "editSequenceTimeline"
	| "editLearningPathOnCompletion"
	| "editUnlockTrigger"
	| "unsavedChanges"
	| "share"

interface DragDropItem {
	droppableId: "sequenceCanvas" | UUID
	index: number
}

interface DragDropResult {
	draggableId: string
	type: "sequence" | "course"
	source: DragDropItem
	destination: DragDropItem
}

interface Props {
	orgId: string
	publishLearningPath: (orgId: string, learningPath: LearningPath, updatedSequences: UpdatedSequences) => void
	learningPaths: LearningPath[]
	roleUserGroups: UserGroup[]
}

interface Params {
	id: string
	roleId: string
}

function arraymove(arr: any[], fromIndex: number, toIndex: number) {
	var element = arr[fromIndex]
	arr.splice(fromIndex, 1)
	arr.splice(toIndex, 0, element)
}

const LearningPathBuilder = ({ orgId, learningPaths, publishLearningPath, roleUserGroups }: Props) => {
	const { id } = useParams<Params>()
	const [userGroupId] = useUrlQueryString("userGroupId")
	const [modalState, setModalState] = useState<ModalState>("confirm")
	const [originalLearningPath, setOriginalLearningPath] = useState<LearningPath | undefined>(undefined)
	const [learningPath, setLearningPath] = useState<LearningPath | undefined>(undefined)
	const [deletedSequenceIds, setDeletedSequenceIds] = useState<UUID[]>([])
	const [updatedSequences, setUpdatedSequences] = useState<UpdatedSequences>({})
	const [sequenceIndex, setSequenceIndex] = useState<number | undefined>(undefined)
	const [editorColumnState, setEditorColumnState] = useState<EditorColumnState>("timeline")
	const [modalType, setModalType] = useState<ModalTypes>("none")

	const roleLookup = roleUserGroups.filter((item) => item.id === userGroupId)

	const closeModal = () => {
		setModalType("none")
		setModalState("confirm")
	}

	const deleteSequence = (sequenceId: UUID) => {
		if (!learningPath) return
		let updatedLearningPath = {
			...learningPath,
			sequences: learningPath.sequences.filter((sequence) => sequence.id !== sequenceId)
		}
		updatedLearningPath = new LearningPathContent(updatedLearningPath).recalculateTimeline()
		setLearningPath(updatedLearningPath)
		setSequenceIndex(undefined)
		if (updatedSequences[sequenceId] !== "added") {
			setDeletedSequenceIds([...deletedSequenceIds, sequenceId])
		}
		closeModal()
	}

	const createSequence = (sequence: Sequence) => {
		if (!learningPath || sequenceIndex === undefined) return
		const sequences = learningPath.sequences
		sequences.splice(sequenceIndex, 0, sequence)
		let updatedLearningPath = {
			...learningPath,
			sequences: sequences
		}
		updatedLearningPath = new LearningPathContent(updatedLearningPath).recalculateTimeline()
		setLearningPath(updatedLearningPath)
		closeModal()
	}

	const updateSequence = (sequence: Sequence) => {
		if (!learningPath) return
		const index = learningPath.sequences.map((item) => item.id).indexOf(sequence.id)
		let updatedLearningPath = {
			...learningPath
		}
		updatedLearningPath.sequences[index] = sequence
		updatedLearningPath = new LearningPathContent(updatedLearningPath).recalculateTimeline()
		setLearningPath(updatedLearningPath)
		closeModal()
	}

	const handleDragDrop = (result: DragDropResult) => {
		if (
			learningPath &&
			result.type === "sequence" &&
			result.destination &&
			result.destination.droppableId === "sequenceCanvas"
		) {
			// Reordering of sequences
			arraymove(learningPath.sequences, result.source.index, result.destination.index)
			let updatedLearningPath = new LearningPathContent(learningPath).recalculateTimeline()
			setLearningPath(updatedLearningPath)
		}
		if (learningPath && result.type === "course" && result.source.droppableId !== "library") {
			// Reorder courses within a sequence
			if (result.source.droppableId === result.destination.droppableId) {
				const selectedSequence = learningPath.sequences.filter(
					(item) => item.id === result.source.droppableId
				)[0]
				arraymove(selectedSequence.content, result.source.index, result.destination.index)
				updateSequence(selectedSequence)
			}
			// Move a course from one sequence to another
			if (
				result.destination &&
				result.source.droppableId !== result.destination.droppableId &&
				result.destination.droppableId !== "library"
			) {
				const sourceSequenceIndex = learningPath.sequences
					.map((item) => item.id)
					.indexOf(result.source.droppableId)
				const destinationSequenceIndex = learningPath.sequences
					.map((item) => item.id)
					.indexOf(result.destination.droppableId)
				let updatedLearningPath = {
					...learningPath
				}
				updatedLearningPath.sequences[sourceSequenceIndex] = new SequenceContent(
					learningPath.sequences[sourceSequenceIndex]
				).removeCourseFromSequence(result.source.index)
				updatedLearningPath.sequences[destinationSequenceIndex] = new SequenceContent(
					learningPath.sequences[destinationSequenceIndex]
				).addCourseToSequence(result.draggableId, result.destination.index)
				updatedLearningPath = new LearningPathContent(updatedLearningPath).recalculateTimeline()
				setLearningPath(updatedLearningPath)
			}
		}
		// Add a course from the library to a sequence
		if (
			learningPath &&
			result.type === "course" &&
			result.destination &&
			result.source.droppableId === "library" &&
			result.destination.droppableId !== "library" &&
			learningPath.sequences.filter((item) => item.id === result.destination.droppableId).length > 0
		) {
			const selectedSequence = learningPath.sequences.filter(
				(item) => item.id === result.destination.droppableId
			)[0]
			const updatedSequence = new SequenceContent(selectedSequence).addCourseToSequence(
				result.draggableId,
				result.destination.index
			)
			updateSequence(updatedSequence)
		}
	}

	const updateLearningPath = (learningPath: LearningPath) => {
		if (!learningPath) return
		let updatedLearningPath = {
			...learningPath
		}
		updatedLearningPath = new LearningPathContent(updatedLearningPath).recalculateTimeline()
		setLearningPath(updatedLearningPath)
		closeModal()
	}

	const findLearningPathById = async () => {
		try {
			await updateFirebaseToken()
			const { data } = await axios.get(`/api/learningPath/${orgId}/${id}`)
			const originalLearningPath = JSON.parse(JSON.stringify(data))
			setOriginalLearningPath(originalLearningPath)
			setLearningPath(data)
			setDeletedSequenceIds([])
		} catch (err) {
			console.log(err)
		}
	}

	const handlePublish = async () => {
		try {
			if (learningPath) {
				await publishLearningPath(orgId, learningPath, updatedSequences)
				setModalState("complete")
				await findLearningPathById()
			} else {
				setModalState("error")
			}
		} catch (err) {
			console.log(err)
			setModalState("error")
		}
	}

	useEffect(() => {
		findLearningPathById()
	}, [learningPaths])

	useEffect(() => {
		if (learningPath && originalLearningPath) {
			const updates = new LearningPathContent(learningPath).calculateSequenceUpdates(
				originalLearningPath,
				deletedSequenceIds
			)
			setUpdatedSequences(updates)
		}
	}, [learningPath, deletedSequenceIds])

	if (!learningPath) {
		return <CbLoading fullpage />
	}

	if (roleLookup.length === 0) {
		return <CbLoading fullpage />
	}

	return (
		<>
			<Nav
				orgId={orgId}
				learningPath={learningPath}
				unsavedChanges={!_.isEqual(learningPath, originalLearningPath)}
				learningPathWarnings={new LearningPathContent(learningPath).calculateLearningPathWarnings()}
				setModalType={setModalType}
				handleUndoButton={findLearningPathById}
			/>
			<S.BuilderOuterContainer>
				<GuideColumn
					learningPath={learningPath}
					setModalType={setModalType}
					learningPathWarnings={new LearningPathContent(learningPath).calculateLearningPathWarnings()}
				/>
				<DragDropContext onDragEnd={handleDragDrop}>
					<ContentColumn
						learningPath={learningPath}
						setSequenceIndex={setSequenceIndex}
						setModalType={setModalType}
						updateSequence={updateSequence}
					/>
					<EditorColumn
						learningPath={learningPath}
						editorColumnState={editorColumnState}
						setEditorColumnState={setEditorColumnState}
						setSequenceIndex={setSequenceIndex}
						setModalType={setModalType}
						updateLearningPath={updateLearningPath}
					/>
				</DragDropContext>
			</S.BuilderOuterContainer>
			{modalType === "addSequence" && sequenceIndex !== undefined && (
				<CreateEditSequence
					orgId={orgId}
					setModalType={setModalType}
					handleSubmit={createSequence}
					learningPath={learningPath}
					sequenceIndex={sequenceIndex}
				/>
			)}
			{modalType === "editSequence" && sequenceIndex !== undefined && (
				<CreateEditSequence
					orgId={orgId}
					setModalType={setModalType}
					handleSubmit={updateSequence}
					learningPath={learningPath}
					sequenceIndex={sequenceIndex}
					sequence={learningPath.sequences[sequenceIndex]}
				/>
			)}
			{modalType === "editSequenceTimeline" && sequenceIndex !== undefined && (
				<EditSequenceTimeline
					setModalType={setModalType}
					handleSubmit={updateSequence}
					sequence={learningPath.sequences[sequenceIndex]}
					sequenceIndex={sequenceIndex}
					learningPath={learningPath}
				/>
			)}
			{modalType === "deleteSequence" && sequenceIndex !== undefined && (
				<Modal
					title="Delete"
					open={true}
					onCancel={closeModal}
					okText="Confirm delete"
					onOk={() => deleteSequence(learningPath.sequences[sequenceIndex].id)}
					centered
				>
					<Alert
						message="Are you sure you want to delete? This sequence will be permanently removed from your learning path when you
					publish"
						type="warning"
					/>
				</Modal>
			)}
			{modalType === "unsavedChanges" && (
				<Modal title="You have unsaved changes" open={true} onCancel={closeModal} footer={null} centered>
					<Alert
						message="Your learning path has unsaved changes. Either Undo all changes or Save before going back"
						type="warning"
					/>
				</Modal>
			)}
			{modalType === "editLearningPathOnCompletion" && (
				<EditLearningPathOnCompletion
					learningPath={learningPath}
					onCancel={() => setModalType("none")}
					updateLearningPath={updateLearningPath}
					role={roleLookup[0]}
				/>
			)}
			{modalType === "editUnlockTrigger" && (
				<EditUnlockTrigger
					learningPath={learningPath}
					onCancel={() => setModalType("none")}
					updateLearningPath={updateLearningPath}
				/>
			)}
			{modalType === "save" && (
				<PublishLearningPath
					closeModal={closeModal}
					modalState={modalState}
					setModalState={setModalState}
					setModalType={setModalType}
					handlePublish={handlePublish}
					updatedSequences={updatedSequences}
					originalLearningPath={originalLearningPath}
					learningPath={learningPath}
				/>
			)}
			{modalType === "share" && (
				<ShareLearningPath
					learningPath={learningPath}
					closeModal={() => setModalType("none")}
					role={roleLookup[0]}
				/>
			)}
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	learningPaths: selectLearningPaths,
	roleUserGroups: selectRoleTypeUserGroups
})

const mapDispatchToProps = (dispatch: any) => ({
	publishLearningPath: (orgId: string, learningPath: LearningPath, updatedSequences: UpdatedSequences) =>
		dispatch(publishLearningPath(orgId, learningPath, updatedSequences))
})

export default connect(mapStateToProps, mapDispatchToProps)(LearningPathBuilder)
