import React, { useState, useEffect } from "react"
import { Redirect } from "react-router-dom"
import axios from "axios"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"

import { selectOrgId, selectOrgUserGroups } from "../../redux/org/org.selectors"
import { clearOrgUserList } from "../../redux/management/management.actions"
import * as S from "../../components/styles/PageStyles"

import LpBackButton from "../../components/common/LpBackButton"
import UploadUsersCsv from "../../components/management/importUsers/UploadUsersCsv"
import ReviewImportedUsers from "../../components/management/importUsers/ReviewImportedUsers"
import TagImportedUsers from "../../components/management/importUsers/TagImportedUsers"
import CompleteImportUsers from "../../components/management/importUsers/CompleteImportUsers"
import ImportSummary from "../../components/management/importUsers/ImportSummary"

/*
We will handle most of the state on this component
and pass props down to the the components which represnet: 'import' -> 'review' -> 'tag' -> 'complete'
*/

const ImportUsers = ({ orgId, orgUserGroups, clearOrgUserList }) => {
	// handle stage of import UI
	const [importStage, setImportStage] = useState("import") // 'import' -> 'review' -> 'tag' -> 'complete' -> 'summary'

	// handle upload of CSV file
	const [localCsvFile, setLocalCsvFile] = useState(null) // where we store csv file
	const [csvCheckResponse, setCsvCheckResponse] = useState(null) // where we store cleaned data res from API

	// derived check for any non empty custom-id entries in import
	let customIdsInImport = false
	if (csvCheckResponse && csvCheckResponse.cleanedData && csvCheckResponse.cleanedData.data) {
		csvCheckResponse.cleanedData.data.forEach((row) => {
			if (row["Custom id"] && row["Custom id"] !== "") {
				customIdsInImport = true
			}
		})
	}

	let customNamesInImport = false
	if (csvCheckResponse && csvCheckResponse.cleanedData && csvCheckResponse.cleanedData.data) {
		csvCheckResponse.cleanedData.data.forEach((row) => {
			if (row["Custom name"] && row["Custom name"] !== "") {
				customNamesInImport = true
			}
		})
	}

	// derived check for any non empty user group entries in import
	let userGroupsInImport = false
	if (csvCheckResponse && csvCheckResponse.cleanedData && csvCheckResponse.cleanedData.data) {
		csvCheckResponse.cleanedData.data.forEach((row) => {
			if (row["User groups"] && row["User groups"] !== "") {
				userGroupsInImport = true
			}
		})
	}

	// when the user adds a csv automatically trigger check via API
	useEffect(() => {
		if (localCsvFile && localCsvFile.file.csv && localCsvFile.file.csv.name) {
			const formData = new FormData()
			// append csv to form
			formData.append("csv", localCsvFile.file.csv, localCsvFile.file.csv.name)
			// check the data via backend
			axios
				.post(`/api/data/${orgId}/import-users/check-csv`, formData)
				.then((res) => {
					setCsvCheckResponse(res.data)
				})
				.catch((err) => {
					console.log("err: ", err)
				})
		}
	}, [localCsvFile, orgId])

	// settings for adding tags to import, use existing user groups (except 'registered')
	const groupOptions = []
	Object.keys(orgUserGroups).forEach((userGroupId) => {
		if (userGroupId !== "registered") {
			groupOptions.push({
				value: userGroupId,
				label: userGroupId,
				isFixed: false
			})
		}
	})
	const [userGroups, setUserGroups] = useState([]) // store user groups to tag imported users with
	const [userGroupUpdateBehaviour, setUserGroupUpdateBehaviour] = useState("add") // the default setting is 'add' which adds users to selected user groups ('registered' is default group)
	const [importSummary, setImportSummary] = useState(null)

	const handleImportRequest = () => {
		// store data to send to API
		const formData = new FormData()
		formData.set("passToImport", true) // this tells API to try and import this data after check
		formData.append("userGroups[]", "registered") // we add the default registered user group here

		for (var i = 0; i < userGroups.length; i++) {
			formData.append("userGroups[]", userGroups[i]) // add any other user groups defined
		}
		formData.set("userGroupUpdateBehaviour", userGroupUpdateBehaviour)
		formData.append("csv", localCsvFile.file.csv, localCsvFile.file.csv.name)

		axios
			.post(`/api/data/${orgId}/import-users-from-csv/`, formData)
			.then((res) => {
				setImportSummary(res.data)
				clearOrgUserList() // FIX THIS - not updating straight away
			})
			.catch((err) => {
				console.log("err: ", err)
			})
	}

	const [returnToUsersScreen, setReturnToUsersScreen] = useState(false)
	if (returnToUsersScreen === true)
		return (
			<Redirect
				to={`/org/${orgId}/manage/users?filters=%5B%7B"member"%3A"registrationStatus"%2C"operator"%3A"includes"%2C"values"%3A%5B"unregistered"%5D%7D%5D`}
			/>
		)

	return (
		<React.Fragment>
			<S.PageContainer>
				{/* import user list via csv */}
				{importStage === "import" && (
					<>
						<S.BackContainer>
							<LpBackButton
								backlink={`/org/${orgId}/manage/users?filters=%5B%7B"member"%3A"registrationStatus"%2C"operator"%3A"includes"%2C"values"%3A%5B"registered"%5D%7D%5D`}
							/>
						</S.BackContainer>

						<UploadUsersCsv
							setImportStage={setImportStage}
							localCsvFile={localCsvFile}
							setLocalCsvFile={setLocalCsvFile}
						/>
					</>
				)}

				{/* tag the users with user group(s) */}
				{importStage === "review" && (
					<>
						<S.BackContainer>
							<LpBackButton backOnClick={() => setImportStage("import")} />
						</S.BackContainer>

						<ReviewImportedUsers
							setImportStage={setImportStage}
							setLocalCsvFile={setLocalCsvFile}
							csvCheckResponse={csvCheckResponse}
							customIdsInImport={customIdsInImport}
							customNamesInImport={customNamesInImport}
							userGroupsInImport={userGroupsInImport}
						/>
					</>
				)}

				{/* tag the users with user group(s) */}

				{importStage === "tag" && (
					<>
						<S.BackContainer>
							<LpBackButton backOnClick={() => setImportStage("review")} />
						</S.BackContainer>

						<TagImportedUsers
							setImportStage={setImportStage}
							userGroups={userGroups}
							setUserGroups={setUserGroups}
							userGroupUpdateBehaviour={userGroupUpdateBehaviour}
							setUserGroupUpdateBehaviour={setUserGroupUpdateBehaviour}
							userGroupsInImport={userGroupsInImport}
						/>
					</>
				)}

				{/* review and complete import */}
				{importStage === "complete" && (
					<>
						<S.BackContainer>
							<LpBackButton backOnClick={() => setImportStage("review")} />
						</S.BackContainer>

						<CompleteImportUsers
							setImportStage={setImportStage}
							userGroups={userGroups}
							csvCheckResponse={csvCheckResponse}
							userGroupUpdateBehaviour={userGroupUpdateBehaviour}
							handleImportRequest={handleImportRequest}
							setReturnToUsersScreen={setReturnToUsersScreen}
							userGroupsInImport={userGroupsInImport}
						/>
					</>
				)}

				{/* see summary of import action */}
				{importStage === "summary" && (
					<>
						<S.BackContainer>
							<LpBackButton />
						</S.BackContainer>

						<ImportSummary importSummary={importSummary} setReturnToUsersScreen={setReturnToUsersScreen} />
					</>
				)}
			</S.PageContainer>
		</React.Fragment>
	)
}

ImportUsers.propTypes = {
	orgId: PropTypes.string.isRequired,
	orgUserGroups: PropTypes.object.isRequired
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	orgUserGroups: selectOrgUserGroups
})

const mapDispatchToProps = (dispatch) => ({
	clearOrgUserList: () => dispatch(clearOrgUserList())
})

export default connect(mapStateToProps, mapDispatchToProps)(ImportUsers)
